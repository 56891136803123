<template>
  <div class="article-base" :style="articleStyle.articleBase">
    <div class="article-box" :style="articleStyle.articleBox">
      <!-- 左侧栏 -->
      <div class="article-left-box" :style="articleStyle.leftBox">
        <div class="article-userinfo" :style="articleStyle.userinfo">

        </div>
      </div>
      <!-- 文章框 -->
      <div class="article-content-box" :style="articleStyle.contentBox">
        <div class="markdown-body" v-html="original"></div>
      </div>
      <!-- 右侧栏 -->
      <div class="article-right-box" :style="articleStyle.rightBox">
        <div class="article-right-menu-box" :style="articleStyle.rightMenuBox"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    dataInitialize();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 常量
  const router = useRouter();
  const mermaid = inject('$mermaid');
  const axiosApi = inject('$axiosApi');
  const commonUtils = inject('$commonUtils');

  // 变量
  let windWidth = window.innerWidth;
  let original = ref(null);
  let articleStyle = ref({
    articleBase: { 'padding-top': null, 'padding-bottom': null },
    articleBox: { 'width': null, 'flex-direction': null },
    leftBox: { 'width': null, 'height': null },
    userinfo: { 'width': null, 'height': null, 'border-radius': null },
    contentBox: {
      'width': null,
      'padding': null,
      'margin-left': null,
      'margin-right': null,
      'border-top': null,
      'border-bottom': null,
      'border-radius': null,
      'min-height': null
    },
    rightBox: { 'width': null },
    rightMenuBox: {
      'position': null,
      'margin-top': null,
      'width': null,
      'height': null,
      'border-radius': null
    }
  })

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    if (windWidth >= 1200) {
      articleStyle.value.articleBase['padding-top'] = '60px';
      articleStyle.value.articleBase['padding-bottom'] = '20px';
      articleStyle.value.articleBox['width'] = '95%';
      articleStyle.value.articleBox['flex-direction'] = '';
      articleStyle.value.leftBox['width'] = '400px';
      articleStyle.value.leftBox['height'] = 'auto';
      articleStyle.value.userinfo['width'] = '300px';
      articleStyle.value.userinfo['height'] = '300px';
      articleStyle.value.userinfo['border-radius'] = '5px';
      articleStyle.value.contentBox['width'] = '100%';
      articleStyle.value.contentBox['padding'] = '30px';
      articleStyle.value.contentBox['margin-left'] = '10px';
      articleStyle.value.contentBox['margin-right'] = '10px';
      articleStyle.value.contentBox['border-top'] = 'none';
      articleStyle.value.contentBox['border-bottom'] = 'none';
      articleStyle.value.contentBox['border-radius'] = '5px';
      articleStyle.value.contentBox['min-height'] = 'auto';
      articleStyle.value.rightBox['width'] = '100px';
      articleStyle.value.rightMenuBox['position'] = 'fixed';
      articleStyle.value.rightMenuBox['margin-top'] = '100px';
      articleStyle.value.rightMenuBox['width'] = '80px';
      articleStyle.value.rightMenuBox['height'] = '500px';
      articleStyle.value.rightMenuBox['border-radius'] = '5px';
    } else {
      articleStyle.value.articleBase['padding-top'] = '50px';
      articleStyle.value.articleBase['padding-bottom'] = '0';
      articleStyle.value.articleBox['width'] = '100%';
      articleStyle.value.articleBox['flex-direction'] = 'column';
      articleStyle.value.leftBox['width'] = '100%';
      articleStyle.value.leftBox['height'] = '100px';
      articleStyle.value.userinfo['width'] = '100%';
      articleStyle.value.userinfo['height'] = '100%';
      articleStyle.value.userinfo['border-radius'] = '0';
      articleStyle.value.contentBox['width'] = 'calc(100% - 40px)';
      articleStyle.value.contentBox['padding'] = '0 20px';
      articleStyle.value.contentBox['margin-left'] = '0';
      articleStyle.value.contentBox['margin-right'] = '0';
      articleStyle.value.contentBox['border-top'] = '1px solid rgba(241, 242, 246, 1.0)';
      articleStyle.value.contentBox['border-bottom'] = '1px solid rgba(241, 242, 246, 1.0)';
      articleStyle.value.contentBox['border-radius'] = '0';
      articleStyle.value.contentBox['min-height'] = 'calc(100% - 180px)';
      articleStyle.value.rightBox['width'] = '100%';
      articleStyle.value.rightMenuBox['position'] = 'relative';
      articleStyle.value.rightMenuBox['margin-top'] = '0';
      articleStyle.value.rightMenuBox['width'] = '100%';
      articleStyle.value.rightMenuBox['height'] = '80px';
      articleStyle.value.rightMenuBox['border-radius'] = '0';
    }
  }

  /**
   * 页面初始化
   */
  function dataInitialize() {
    const formData = new FormData();
    formData.append('aid', new URLSearchParams(window.location.search).get('aid'));
    axiosApi.routine('/article/get_article', 'POST', true, formData, false)
      .then(result => {
        let randM = commonUtils.getRandomString(10) + new Date().getTime();
        let mddata = commonUtils.markdownit(JSON.parse(result.article).content, randM, 'compile');
        original.value = mddata.content;
        nextTick(async () => {
          try {
            await mermaid.run();
            copyTextClass(mdData.randM);
          } catch (__) {}
        });
      }).catch(error => {});
  }

  /**
   * 复制文本
   * @param {dom} elment dom元素
   */
  function copyTextClass(elment) {
    commonUtils.copyTextClass(elment).then(result => {
      messageData.value = result ? "复制成功" : "复制失败";
    })
  }
</script>

<style scoped>
  @import "@/assets/css/share/universal.css";

  .article-base {
    position: relative;
    width: 100%;
    min-height: calc(100% - 80px);
    height: auto;
    padding-top: 60px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    background: url('@/assets/image/rests/background-test.webp');
  }

  .article-box {
    position: relative;
    width: 95%;
    height: auto;
    display: flex;
  }

  .article-left-box {
    min-width: 300px;
    width: 400px;
    height: auto;
  }

  .article-userinfo {
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 5px;
  }

  .article-content-box {
    position: relative;
    padding: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    min-height: auto;
    height: auto;
    background-color: white;
    border-radius: 5px;
  }

  .article-right-box {
    min-width: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
  }

  .article-right-menu-box {
    position: fixed;
    margin-top: 100px;
    width: 80px;
    height: 500px;
    background-color: white;
    border-radius: 5px;
  }
</style>
