<!-- 消息弹窗组件 -->
<template>
  <div class="inquiry-shade">
    <div class="inquiry-box">
      <div class="inquiry-close-box">
        <span class="inquiry-hint">信息</span>
        <div class="inquiry-close" @click="cancelSubmit">
          <span class="iconfont">&#xe65d;</span>
        </div>
      </div>
      <div class="inquiry-message-box">
        <p>{{ data.message }}</p>
      </div>
      <div class="inquiry-button-box">
        <button class="inquiry-cancel" @click="cancelSubmit">取消</button>
        <button class="inquiry-affirm" @click="confirmSubmit">确定</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    defineProps,
    defineEmits
  } from 'vue';

  // 常量
  const emits = defineEmits();
  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  /**
   * 确认选择提交
   */
  function confirmSubmit() {
    emits('inquiryEmits', {
      judeg: true,
      data: props.data
    });
  }

  /**
   * 关闭弹窗
   */
  function cancelSubmit() {
    emits('inquiryEmits', {
      judeg: false,
      data: props.data
    });
  }
</script>

<style scoped>
  .inquiry-shade {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 39, 46, 0.5);
    z-index: 2001;
  }

  .inquiry-box {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 300px;
    height: 180px;
    background-color: white;
    box-shadow: 0 0 15px rgba(53, 59, 72, 0.5);
  }

  .inquiry-close-box {
    height: 20%;
    display: flex;
    align-items: center;
    background-color: rgba(53, 59, 72, 1.0);
  }

  .inquiry-hint {
    user-select: none;
    color: white;
    font-size: 15px;
    margin-left: 20px;
    color: rgba(220, 221, 225, 0.8);
    font-weight: bold;
  }

  .inquiry-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .inquiry-close:active {
    background-color: rgba(220, 221, 225, 0.2);
  }

  .inquiry-close span {
    user-select: none;
    font-size: 17px;
    color: rgba(241, 242, 246,0.9);
  }

  .inquiry-message-box {
    user-select: none;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inquiry-message-box p {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 75%;
    word-break: break-all;
    font-size: 15px;
    color: rgba(47, 54, 64, 0.8);
    border: 1px solid rgba(53, 59, 72, 0.2);
    padding: 5px;
  }

  .inquiry-button-box {
    user-select: none;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .inquiry-button-box button {
    width: 35%;
    height: 70%;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .inquiry-affirm {
    border: none;
    background-color: rgba(0, 168, 255, 0.8);
    color: white;
  }

  .inquiry-affirm:active {
    background-color: rgba(0, 168, 255, 0.5);
  }

  .inquiry-cancel {
    border: none;
    background-color: rgba(225, 177, 44, 0.8);
    color: white;
  }

  .inquiry-cancel:active {
    background-color: rgba(225, 177, 44, 0.5);
  }
</style>
