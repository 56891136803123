<template>
  <div class="paging-base">
    <div class="paging-box">
      <button :class="previousClass" :disabled="pagingLast <= 1" @click="theFirstPlace(true)"><span class="iconfont">&#xe74e;</span></button>
      <button :class="previousClass" :disabled="pagingLast <= 1" @click="previousPage(true)"><span class="iconfont">&#xe646;</span></button>
      <button :class="item.class" v-for="(item, index) in pagingList" @click="pagingPush(item)">{{ item.index }}</button>
      <button :class="nextClass" :disabled="pagingLast >= pagingCount" @click="nextPage(true)"><span class="iconfont">&#xe647;</span></button>
      <button :class="nextClass" :disabled="pagingLast >= pagingCount" @click="theFirstPlace(false)"><span class="iconfont">&#xe74d;</span></button>
      <div class="paging-skip-box" v-if="showJudeg">
        <input v-model="pagingSkip" type="number" max="100" @input="limitMaxValue" @keydown.enter="inputSkip" />
        <span class="iconfont" @click="inputSkip">&#xe60c;</span>
      </div>
      <div class="paging-strip-box" v-if="showJudeg">
        <el-dropdown class="" placement="top">
          <span class="paging-el-dropdown-link">每页展示{{ strip }}条-共{{ pagingCount }}页<el-icon>
              <ArrowRight />
            </el-icon></span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="setStrip(10)">10</el-dropdown-item>
              <el-dropdown-item @click="setStrip(20)">20</el-dropdown-item>
              <el-dropdown-item @click="setStrip(50)">50</el-dropdown-item>
              <el-dropdown-item @click="setStrip(80)">80</el-dropdown-item>
              <el-dropdown-item @click="setStrip(100)">100</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    defineProps,
    defineEmits,
    defineExpose,
    watch
  } from 'vue';
  import {
    ArrowRight
  } from '@element-plus/icons-vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    initializePaging(true, true);
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 组件参数
  const props = defineProps({
    // 数据总数
    sum: {
      type: Number,
      required: true
    }
  });

  // 常量
  const emits = defineEmits();

  // 变量
  let windWidth = window.innerWidth;
  let strip = ref(10);
  let pagingList = ref([]);
  let pagingLast = ref(0);
  let pagingCount = ref(Math.ceil(props.sum / strip.value));
  let forCount = pagingCount.value <= 5 ? pagingCount.value : 5;
  let previousClass = ref('paging-immobilization-button');
  let nextClass = ref('paging-immobilization-button');
  let pagingSkip = ref(null);
  let paginationJudeg = ref(false);
  let showJudeg = ref(true);

  /**
   * 暴露分页数据给父类
   */
  defineExpose({ pagingLast, strip, pagingList, paginationJudeg });

  /**
   * 监听sum参数变化
   */
  watch(() => [props.sum, strip.value], () => { reset() });

  /**
   * 页面初始化
   */
  function pageInitialize(){
    if(windWidth >= 600){
      showJudeg.value = true;
    }else{
      showJudeg.value = false;
    }
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 页面重置
   */
  function reset() {
    pagingCount.value = Math.ceil(props.sum / strip.value);
    forCount = pagingCount.value <= 5 ? pagingCount.value : 5;
    if (paginationJudeg.value) {
      initializePaging(false, true);
      if(pagingLast.value > pagingList.value.length){
        emits('pagingEmits', { limitOrigin: pagingLast.value -2, limitStrip: strip.value });
        pagingLast.value = pagingList.value.length;
      }else if(props.sum === 0){
        initializePaging(true, true);
        pagingList.value = [];
      }
      dynamicButtomState();
      paginationJudeg.value = false;
    } else {
      pagingLast.value = 0;
      initializePaging(true, true);
    }
  }

  /**
   * 初始化分页按钮
   * @param {boolean} 首页或者尾页
   */
  function initializePaging(initialize, judeg) {
    if (pagingCount.value !== 0) {
      pagingList.value = [];
      if (judeg) {
        for (let i = 0; i < forCount; i++) {
          pagingList.value.push({ index: i + 1, class: 'paging-dynamic' });
        }
      } else {
        for (let i = 0; i < forCount; i++) {
          pagingList.value.unshift({ index: pagingCount.value - i, class: 'paging-dynamic' });
        }
      }
      if (initialize) {
        pagingList.value[0].class = 'paging-active';
        pagingLast.value = 1;
      }
    }
    immobilizationButtonState();
  }


  /**
   * 跳转首页或者位置
   * @param {boolean} judeg 首页或者尾页
   */
  function theFirstPlace(judeg) {
    if (judeg) {
      initializePaging(false, true);
      pagingList.value[0].class = 'paging-active';
      pagingLast.value = 1;
      emits('pagingEmits', { limitOrigin: 0, limitStrip: strip.value });
    } else {
      initializePaging(false, false);
      pagingList.value[pagingList.value.length - 1].class = 'paging-active';
      pagingLast.value = pagingCount.value;
      emits('pagingEmits', { limitOrigin: pagingLast.value - 1, limitStrip: strip.value });
    }
    immobilizationButtonState();
  }

  /**
   * 分页按钮点击事件
   */
  function pagingPush(item) {
    if (pagingLast.value !== item.index) {
      let last = pagingLast.value;
      pagingLast.value = item.index;
      if (last > item.index) {
        previousPage(false);
      } else {
        nextPage(false);
      }
      immobilizationButtonState();
      emits('pagingEmits', { limitOrigin: item.index - 1, limitStrip: strip.value });
    }
  }

  /**
   * 上一页
   * @param {boolean} judeg 是否为按钮触发
   */
  function previousPage(judeg) {
    if (judeg) {
      pagingLast.value -= 1;
      emits('pagingEmits', { limitOrigin: pagingLast.value - 1, limitStrip: strip.value });
    }
    if (pagingList.value.length >= 5 && pagingList.value[0].index - 1 > 0) {
      pagingList.value.forEach(item => { item.index -= 1; })
    } else {
      initializePaging(false, true);
    }
    dynamicButtomState();
    immobilizationButtonState();
  }

  /**
   * 下一页
   * @param {boolean} judeg 是否为按钮触发
   */
  function nextPage(judeg) {
    if (judeg) {
      emits('pagingEmits', { limitOrigin: pagingLast.value, limitStrip: strip.value });
      pagingLast.value += 1;
    }
    if (pagingList.value.length >= 5 && pagingList.value[4].index + 1 < pagingCount.value) {
      pagingList.value.forEach(item => { item.index += 1; })
    } else {
      initializePaging(false, false);
    }
    dynamicButtomState();
    immobilizationButtonState();
  }

  /**
   * 动态按钮展示状态
   */
  function dynamicButtomState() {
    pagingList.value.forEach(item => {
      if (pagingLast.value === item.index) {
        item.class = 'paging-active';
      } else {
        item.class = 'paging-dynamic';
      }
    })
  }

  /**
   * 固定按钮展示状态
   */
  function immobilizationButtonState() {
    if (pagingLast.value <= 1) {
      previousClass = 'paging-forbid';
    } else {
      previousClass = 'paging-immobilization-button';
    }
    if (pagingLast.value >= pagingCount.value) {
      nextClass = 'paging-forbid';
    } else {
      nextClass = 'paging-immobilization-button';
    }
  }

  /**
   * 输入跳转
   */
  function inputSkip() {
    if (pagingSkip.value) {
      pagingLast.value = pagingSkip.value;
      if (pagingSkip.value <= pagingList.value.length) {
        initializePaging(false, true);
      } else {
        if (pagingSkip.value + pagingList.value.length > pagingCount.value) {
          initializePaging(false, false);
        } else {
          for (let i = 0; i < pagingList.value.length; i++) {
            switch (i) {
              case 0:
                pagingList.value[0].index = pagingSkip.value - 2;
                break;
              case 1:
                pagingList.value[1].index = pagingSkip.value - 1;
                break;
              case 2:
                pagingList.value[2].index = pagingSkip.value;
                break;
              case 3:
                pagingList.value[3].index = pagingSkip.value + 1;
                break;
              case 4:
                pagingList.value[4].index = pagingSkip.value + 2;
                break;
            }
          }
        }
      }
      dynamicButtomState();
      immobilizationButtonState();
      emits('pagingEmits', { limitOrigin: pagingSkip.value - 1, limitStrip: strip.value });
      pagingSkip.value = null;
    }
  }

  /**
   * 修正跳转最大值
   */
  function limitMaxValue(event) {
    if (parseInt(event.target.value, 10) > pagingCount.value) {
      pagingSkip.value = pagingCount.value;
    }
  }

  /**
   * 设置每页展示条数
   */
  function setStrip(value) {
    strip.value = value;
    emits('pagingEmits', { limitOrigin: 0, limitStrip: strip.value });
  }
</script>

<style scoped>
  .paging-base {
    position: relative;
    width: 100%;
    height: auto;
  }

  .paging-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .paging-immobilization-button {
    cursor: pointer;
    user-select: none;
    margin-left: 3px;
    margin-right: 3px;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(87, 96, 111, 0.3);
    border-radius: 5px;
    color: black;
    background-color: white;
    transition: background-color 0.1s ease-in-out;
  }

  .paging-immobilization-button button:active {
    border: 1px solid white;
  }

  .paging-immobilization-button button span {
    color: rgba(112, 161, 255, 1.0);
    font-size: 15px;
  }

  .paging-dynamic {
    cursor: pointer;
    user-select: none;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 30px;
    width: auto;
    height: 30px;
    border: none;
    border-radius: 50%;
    color: black;
    background-color: white;
    transition: background-color 0.1s ease-in-out;
  }

  .paging-active {
    cursor: pointer;
    user-select: none;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 30px;
    width: auto;
    height: 30px;
    border: 1px solid white;
    border-radius: 50%;
    transition: background-color 0.1s ease-in-out;
    color: white;
    background-color: rgba(87, 96, 111, 1.0);
  }

  .paging-forbid {
    user-select: none;
    margin-left: 3px;
    margin-right: 3px;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(87, 96, 111, 0.3);
    border-radius: 5px;
    color: gray;
    background-color: white;
    transition: background-color 0.1s ease-in-out;
    cursor: not-allowed;
  }

  .paging-skip-box {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 25px;
    overflow: hidden;
    border: 1px solid rgba(87, 96, 111, 0.3);
  }

  .paging-skip-box input {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }

  .paging-skip-box span {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 100%;
    font-size: 15px;
    color: white;
    background-color: rgba(87, 96, 111, 0.3);
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
  }

  .paging-skip-box input::-webkit-outer-spin-button,
  .paging-skip-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .paging-strip-box {
    position: absolute;
    right: 30px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }

  .paging-el-dropdown-link {
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paging-el-dropdown-link:focus {
    outline: none;
  }
</style>
