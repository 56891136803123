<!-- 安全验证组件 -->
<template>
  <div class="safety-shade">
    <div class="safety-mask" v-loading="loading" :element-loading-svg="svg" element-loading-svg-view-box="-10, -10, 50, 50"></div>
    <div class="safety-base">
      <div class="safety-close-box">
        <span class="safety-hint">安全验证</span>
        <div class="safety-close" @click="cancelSubmit">
          <span class="iconfont">&#xe65d;</span>
        </div>
      </div>
      <div class="safety-select-button-box">
        <button v-for="(item, index) in selectButtomAll" :style="item.active" @click="selectLoginWay(item)"> {{ item.button }}</button>
      </div>
      <form>
        <div class="safety-form-box">
          <!-- 密码验证 -->
          <div class="safety-input-box" v-if="password">
            <span class="iconfont" v-html="pwdSwitchPric" @click="pwdSwitchShow"></span>
            <input ref="inputRef" class="safety-input-param-box" :type="pwdInputType" v-model="pwdValue" placeholder="输入密码" autocomplete @keydown.enter="confirmSubmit" />
          </div>
          <!-- 短信验证 -->
          <div class="safety-note-box" v-if="note">
            <input class="safety-input-param-box" type="text" placeholder="输入手机号" @keydown.enter="confirmSubmit" />
            <div class="safety-verification_code-box">
              <input class="safety-verification_code-input" type="text" placeholder="输入验证码" @keydown.enter="confirmSubmit" />
              <button type="button">验证码</button>
            </div>
          </div>
        </div>
      </form>
      <div class="safety-submit-box">
        <button class="safety-submit" @click="confirmSubmit">验证</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    inject,
    onMounted,
    defineProps,
    defineEmits
  } from 'vue'

  /**
   * 页面载入时执行
   */
  onMounted(() => {
    inputRef.value.focus();
  });

  // 常量
  const emits = defineEmits();
  const commonUtils = inject("$commonUtils");
  const axiosApi = inject('$axiosApi');
  const AESUtils = inject("$AESUtils");
  const svg = `<path class="path" d="M 30 15 L 28 17 M 25.61 25.61 A 15 15, 0, 0, 1, 15 30 A 15 15, 0, 1, 1, 27.99 7.5 L 15 15 "style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`
  const selectButtomAll = ref([
    { 'button': "密码验证", 'active': { 'color': "black", 'border-bottom': "1px solid rgba(75, 75, 75,1.0)" }, 'value': 1 },
    { 'button': "短信验证", 'active': null, 'value': 2 }
  ]);

  // 变量
  let inputRef = ref(null);
  let password = ref(true);
  let note = ref(false);
  let pwdSwitchPric = ref('&#xe6fb;');
  let pwdInputType = ref("password");
  let pwdValue = ref("");
  let loading = ref(false);
  let selectPric = 1;

  /**
   * 提交验证
   * @param {event} event
   */
  function confirmSubmit(event) {
    event.preventDefault()
    if (selectPric === 1) {
      if (!/^\s*$/.test(pwdValue.value)) {
        let userinfo = commonUtils.emptyCookie("Userinfo", null, true)
        loading.value = true
        let formData = new FormData()
        formData.append("uid", userinfo.uid)
        axiosApi.routine('/user/inquire_password', 'POST', true, formData, true)
          .then(async result => {
            let password = await AESUtils.decrypt(result, 'stair');
            if (password === pwdValue.value) {
              emits('safetyEmits', {
                'judeg': true,
                'data': password
              })
              loading.value = false;
            } else {
              commonUtils.elmentMessage("验证失败", "warning", 30);
              loading.value = false
            }
          })
      } else {
        commonUtils.elmentMessage("格式不为空", "warning", 30);
      }
    } else {
      commonUtils.elmentMessage("短信验证暂未开放", "warning", 30);
    }
  }

  /**
   * 取消验证
   */
  function cancelSubmit() {
    emits('safetyEmits', {
      'judeg': false
    })
  }

  /**
   * 选择验证方式
   * @param {item数据集} item
   */
  function selectLoginWay(item) {
    selectButtomAll.value.forEach(item => {
      item.active = null
    })
    item.active = { 'color': "black", 'border-bottom': "1px solid rgba(75, 75, 75,1.0)" }
    if (item.value === 1) {
      password.value = true
      note.value = false
    } else {
      password.value = false
      note.value = true
    }
    selectPric = item.value
  }

  /**
   * 密码显示控制
   */
  function pwdSwitchShow() {
    if (pwdSwitchPric.value === '&#xe6fb;') {
      pwdSwitchPric.value = '&#xe6f9;';
      pwdInputType.value = "text";
    } else {
      pwdSwitchPric.value = '&#xe6fb;';
      pwdInputType.value = "password";
    }
  }
</script>

<style scoped>
  .safety-mask {
    width: 100%;
    background-color: black;
    transform: translate(-50%, -50%);
    top: 10%;
    left: 50%;
    position: fixed;
    z-index: 1999;
  }

  .safety-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 39, 46, 0.5);
    z-index: 2000;
  }

  .safety-base {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 300px;
    height: 250px;
    background-color: white;
    box-shadow: 0 0 15px rgba(53, 59, 72, 0.5);
  }

  .safety-select-button-box {
    user-select: none;
    height: 35px;
  }

  .safety-select-button-box button {
    width: 50%;
    height: 100%;
    background: none;
    color: rgba(248, 165, 194, 1.0);
    border: none;
  }

  .safety-close-box {
    height: 35px;
    display: flex;
    align-items: center;
    background-color: rgba(53, 59, 72, 1.0);
  }

  .safety-hint {
    max-width: 70%;
    user-select: none;
    color: white;
    font-size: 15px;
    margin-left: 20px;
    color: rgba(220, 221, 225, 0.8);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .safety-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .safety-close:active {
    background-color: rgba(220, 221, 225, 0.2);
  }

  .safety-close span {
    user-select: none;
    font-size: 17px;
    color: rgba(241, 242, 246, 0.9);
  }

  .safety-form-box {
    user-select: none;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .safety-input-box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .safety-input-box span {
    right: 25px;
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: rgba(241, 242, 246, 1.0);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: rgba(75, 75, 75, 1.0);
    -webkit-tap-highlight-color: transparent;
  }

  .safety-input-param-box {
    padding: 5px;
    margin: 10px;
    width: 80%;
    height: 25px;
    outline: none;
    font-size: 15px;
    border: 1px solid rgba(75, 75, 75, 0.3);
    border-radius: 5px;
  }

  .safety-input-param-box:focus {
    border: 1px solid rgba(75, 75, 75, 0.8);
  }

  .safety-note-box {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .safety-verification_code-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .safety-verification_code-input {
    padding: 5px;
    margin-bottom: 10px;
    width: 50%;
    height: 25px;
    outline: none;
    font-size: 15px;
    border: 1px solid rgba(75, 75, 75, 0.3);
    border-radius: 5px;
  }

  .safety-verification_code-input:focus {
    border: 1px solid rgba(75, 75, 75, 0.8);
  }

  .safety-verification_code-box button {
    margin-left: 5px;
    margin-bottom: 10px;
    width: 28%;
    height: 35px;
    border: none;
    color: white;
    background-color: rgba(119, 139, 235, 0.8);
    border-radius: 5px;
  }

  .safety-verification_code-box button:active {
    box-shadow: inset 0 0 5px white;
  }

  .safety-submit-box {
    user-select: none;
    height: calc(100% - 190px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(89, 98, 117, 0.3);
  }

  .safety-submit {
    border: none;
    padding: 10px;
    width: 150px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: rgba(47, 53, 66, 0.5);
    transition: background-color 0.2s ease-in-out;
  }

  .safety-submit:active {
    box-shadow: inset 0 0 5px white;
  }
</style>
