import { createApp, ref } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// 导入常用库
import axios from 'axios';
import jquery from 'jquery';
import cookie from 'js-cookie';
import websocket from 'websocket';
import { marked } from 'marked';
import markdownit from 'markdown-it';
import footnote from 'markdown-it-footnote';
import highlight from 'highlight.js';
import mermaid from 'mermaid';
import clipboard from 'clipboard';
// 导入全局外部js文件函数
import commonUtils from '@/assets/js/commonUtils.js';
import axiosApi from '@/assets/js/axiosApi.js';
import AESUtils from '@/assets/js/AESUtils.js';

// 页面组件
import User from '@/components/layout/User.vue';
import Message from '@/components/toolkit/Message.vue';
import Loading from '@/components/toolkit/Loading.vue';
import Loading1 from '@/components/toolkit/Loading1.vue';
import Inquiry from '@/components/toolkit/Inquiry.vue';
import InputField from '@/components/toolkit/InputField.vue';
import Select from '@/components/toolkit/Select.vue';
import Safety from '@/components/toolkit/Safety.vue';
import InputPwd from '@/components/toolkit/InputPwd.vue';
import PreviewPicture from '@/components/toolkit/PreviewPicture.vue';
import Dropdown from '@/components/toolkit/Dropdown.vue';
import MarkdownEditor from '@/components/toolkit/MarkdownEditor.vue';
import Paging from '@/components/toolkit/Paging.vue';

// 入口组件
const app = createApp(App);
const routePath = ref(null);

// 全局变量
app.provide('$hostServe', 'wss://tkxt.cc:9235/nuyoah/websocket');
// app.provide('$hostServe', "ws://192.168.0.4:8084/nuyoah/websocket");
app.provide('$routePath', routePath); // 保存登录后需返回的页面

// 资源地址
app.provide('$resourceApi', 'https://tkxt.cc:9235/nuyoah');
// app.provide('$resourceApi', "http://localhost:8084/nuyoah");
// 图片资源
app.provide('$imagesPathApi', "https://tkxt.cc:1784/images/");
// app.provide('$imagesPathApi', "http://localhost:8084/nuyoah/images/");
// axios全局配置
const axiosInitialize = axios.create({ baseURL: '/nuyoah', timeout: 100000 });
app.config.globalProperties.$axiosTkxt = axiosInitialize;
app.provide('$axiosTkxt', axiosInitialize);

// jquery全局配置
app.config.globalProperties.$jquery = jquery;
app.provide('$jquery', jquery);

// Cookie全局配置
app.config.globalProperties.$cookie = cookie;
app.provide('$cookie', cookie);

// WebSoket全局配置
app.config.globalProperties.$websocket = websocket;
app.provide('$websocket', websocket);

// Marked全局配置
app.config.globalProperties.$marked = marked;
app.provide('$marked', marked);

// Markdown全局配置
app.config.globalProperties.$markdownit = markdownit;
app.provide('$markdownit', markdownit);

// Footnote全局配置
app.config.globalProperties.$footnote = footnote;
app.provide('$footnote', footnote);

// Highlight全局配置
app.config.globalProperties.$highlight = highlight;
app.provide('$highlight', highlight);

// Mermaid全局配置
app.config.globalProperties.$mermaid = mermaid;
app.provide('$mermaid', mermaid);

// Clipboard全局配置
app.config.globalProperties.$clipboard = clipboard;
app.provide('$clipboard', clipboard);

// 自定义js外部函数全局配置
app.config.globalProperties.$commonUtils = commonUtils;
app.provide('$commonUtils', commonUtils);
app.config.globalProperties.$axiosApi = axiosApi;
app.provide('$axiosApi', axiosApi);
app.config.globalProperties.$AESUtils = AESUtils;
app.provide('$AESUtils', AESUtils);

// 自定义组件
app.component('User', User);
app.component('Message', Message);
app.component('Loading', Loading);
app.component('Loading1', Loading1);
app.component('Inquiry', Inquiry);
app.component('InputField', InputField);
app.component('Select', Select);
app.component('Safety', Safety);
app.component('InputPwd', InputPwd);
app.component('PreviewPicture', PreviewPicture);
app.component('Dropdown', Dropdown);
app.component('MarkdownEditor', MarkdownEditor);
app.component('Paging', Paging);

// 插件注册
app.use(store);
app.use(router);
app.use(ElementPlus);
app.mount('#app');
