<template>
  <div class="writing-base" v-loading="loading" element-loading-background="rgba(255, 255, 255,0.3)">
    <div class="writing-issue-base" v-show="issueBoxJudeg">
      <div class="writing-issue-box" :style="writingStyle.issueBox">
        <div class="writing-issue-title">
          <span class="writing-issue-title-text">发布文章</span>
          <span class="iconfont writing-issue-title-close" @click="issueBoxJudeg = false">&#xe600;</span>
        </div>
        <div class="writing-issue-centre">

          <div class="writing-issue-cover-box">
            <div class="writing-issue-cover-hint">
              <span>添加封面</span>
            </div>
            <div class="writing-issue-cover-uploading-box">
              <input ref="coverFileRef" type="file" v-show="false" accept="image/*" @change="afterSelectionImage" />
              <div class="writing-issue-cover-uploading" :style="imgFileStyle" @click="coverFileRef.click()">
                <div class="writing-issue-cover-icon">
                  <span class="iconfont">&#xe716;</span>
                  <p>点击上传封面</p>
                </div>
                <img class="writing-issue-cover-img" :src="inputFileUrl" v-if="inputFileUrl" alt="">
                <span class="iconfont writing-issue-cover-img-close" v-if="inputFileUrl" @click.stop="deleteimageFile">&#xe65d;</span>
              </div>
            </div>
          </div>

          <div class="writing-issue-label-box">
            <div class="writing-issue-label-hint">
              <span>文章标签</span>
            </div>
            <div class="writing-issue-label-select-box">

            </div>
          </div>

          <div class="writing-issue-special-column-box">
            <div class="writing-issue-special-column-hint">
              <span>分类专栏</span>
            </div>
            <div class="writing-issue-special-column-select-box">

            </div>
          </div>

          <div class="writing-issue-type-box">
            <div class="writing-issue-type-hint">
              <span>文章类型</span>
            </div>
            <div class="writing-issue-type-select-box">

            </div>
          </div>

          <div class="writing-issue-visible-box">
            <div class="writing-issue-visible-hint">
              <span>可见范围</span>
            </div>
            <div class="writing-issue-visible-select-box">

            </div>
          </div>

          <div class="writing-issue-digest-box">
            <div class="writing-issue-digest-hint">
              <span>文章摘要</span>
            </div>
            <div class="writing-issue-digest-input-box">
              <textarea v-model="articleDigest" maxlength="100" @input="digestCount"></textarea>
              <span class="writing-issue-digest-input-count">{{ articleDigestCount }}/100</span>
            </div>
          </div>

        </div>
        <div class="writing-issue-muen-button">
          <div class="writing-issue-muen-button-box">
            <button @click="issueBoxJudeg = false">取消</button>
            <!-- <button>保存</button>
            <button>定时</button> -->
            <button @click="publishArticleAxios">发布</button>
          </div>
        </div>
      </div>
    </div>
    <div class="writing-top-box">
      <div class="writing-return-home" @click="commonUtils.thisSkip(router, '/')">
        <span class="iconfont writing-return-home-icon">&#xe65e;</span>
      </div>
      <input class="writing-title-input" v-model="creationTitle" type="text" placeholder="文章标题..." maxlength="100" @input="titleCount" />
      <span class="writing-issue-title-input-count">{{ creationTitleCount }}/100</span>
      <div class="writing-top-menu-box">
        <!-- <button class="writing-save" type="button">保存草稿</button> -->
        <button class="writing-issue" type="button" @click="issueBoxJudeg = true">发布</button>
        <img src="@/assets/image/system/user_head_default.png" draggable="false" alt>
      </div>
    </div>
    <MarkdownEditor ref="markdownEditorRef" class="writing-markdown-editor"></MarkdownEditor>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    nextTick
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化页面
    pageInitialize();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  /**
   * 常量
   */
  const router = useRouter();
  const axiosApi = inject('$axiosApi');
  const commonUtils = inject('$commonUtils');

  /**
   * 变量
   */
  let windWidth = window.innerWidth;
  let markdownEditorRef = ref(null);
  let issueBoxJudeg = ref(false);
  let coverFileRef = ref(null);
  let creationTitle = ref('');
  let inputFileUrl = ref(null);
  let imgFileStyle = ref({ border: null });
  let articleDigest = ref('');
  let articleDigestCount = ref(0);
  let creationTitleCount = ref(0);
  let loading = ref(false);
  let writingStyle = ref({
    issueBox: { 'width': null, 'border-radius': null }
  })

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    if (windWidth >= 800) {
      writingStyle.value.issueBox['width'] = '800px';
      writingStyle.value.issueBox['border-radius'] = '5px';
    } else {
      writingStyle.value.issueBox['width'] = '100%';
      writingStyle.value.issueBox['border-radius'] = '0';
    }
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 文章发布
   */
  function publishArticleAxios() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      if (/^\s*$/.test(creationTitle.value)) {
        commonUtils.elmentMessage('文章标题不为空', 'info', 60);
        return;
      }
      if (/^\s*$/.test(markdownEditorRef.value.compileContent)) {
        commonUtils.elmentMessage('文章内容不为空', 'info', 60);
        return;
      }
      if (coverFileRef.value.files.length <= 0) {
        commonUtils.elmentMessage('未选择文章封面', 'info', 60);
        return;
      }
      if (/^\s*$/.test(articleDigest.value)) {
        commonUtils.elmentMessage('文章摘要不为空', 'info', 60);
        return;
      }
      loading.value = true;
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      formData.append('title', creationTitle.value);
      formData.append('content', markdownEditorRef.value.compileContent);
      formData.append('cover', coverFileRef.value.files[0]);
      formData.append('digest', articleDigest.value);
      axiosApi.routine('/article/issue', 'POST', true, formData, true)
        .then(result => {
          if (result === 1) {
            commonUtils.thisSkipQuery(router, 'message', { type: 'succeed', condition: 'article', msg: encodeURIComponent('发布成功') });
          } else {
            commonUtils.thisSkipQuery(router, 'message', { type: 'failing', condition: 'article', msg: encodeURIComponent('发布失败') });
          }
          loading.value = false;
        })
        .catch(error => {
          commonUtils.elmentMessage('请求异常：' + error, 'error', 60);
          loading.value = false;
        });
    } else {
      commonUtils.elmentMessage('请先登录', 'info', 60);
    }
  }

  /**
   * 选择完图片进行处理
   * @param {event} event
   */
  function afterSelectionImage(event) {
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.startsWith('image/')) {
        commonUtils.elmentMessage('文件类型必须为图片', 'info', 60);
        return;
      }
      if (event.target.files[0].size > (4 * 1024 * 1024)) {
        commonUtils.elmentMessage('文件大小需小于4MB', 'info', 60);
        return;
      }
      inputFileUrl.value = URL.createObjectURL(event.target.files[0]);
      imgFileStyle.value['border'] = "1px solid rgba(72, 84, 96, 0.3)";
    }
  }

  /**
   * 删除选择的封面图片文件
   */
  function deleteimageFile() {
    coverFileRef.value.value = null;
    inputFileUrl.value = null;
    imgFileStyle.value['border'] = "1px dashed rgba(72, 84, 96, 0.3)";
  }

  /**
   * 文章摘要计数
   */
  function digestCount() {
    articleDigestCount.value = articleDigest.value.length;
  }

  /**
   * 文章标题计数
   */
  function titleCount() {
    creationTitleCount.value = creationTitle.value.length;
  }
</script>

<style scoped>
  @import "@/assets/css/page/writing.css";
  @import "/public/static/dynamic_css/markdown_theme/markdown-default.css";

  .writing-markdown-editor {
    z-index: 1998;
    margin-top: 1px;
    height: calc(100% - 51px) !important;
  }
</style>
