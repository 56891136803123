<template>
  <div class="message-base">
    <div class="message-hint-box">
      <div class="message-hint-font-box"><span class="iconfont" v-html="icon"></span></div>
      <div class="message-hint-msg-box"><span>{{ message }}</span></div>
      <div class="message-hint-skip-box" v-if="condition === 'article'">
        <button @click="commonUtils.thisSkip(router, '/')">回到首页</button>
        <button @click="commonUtils.thisSkip(router, '/creation')">创作中心</button>
        <button @click="commonUtils.thisSkip(router, '/writing')">继续发布</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    inject
  } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    dataInitialize();
  });

  /**
   * 常量
   */
  const route = useRoute();
  const router = useRouter();
  const commonUtils = inject('$commonUtils');

  /**
   * 变量
   */
  let icon = ref('&#xe601;');
  let type = ref(route.query.type);
  let condition = ref(route.query.condition);
  let message = ref(decodeURIComponent(route.query.msg));

  /**
   * 页面数据初始化
   */
  function dataInitialize() {
    switch (condition.value) {
      case 'succeed':
        icon.value = '&#xe601;';
        break;
      case 'failing':
        icon.value = '&#xe60e;';
        break;
      default:
        break;
    }
  }
</script>

<style scoped>
  .message-base {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .message-hint-box {
    margin-top: 100px;
    width: 500px;
    height: 300px;
  }

  .message-hint-font-box {
    text-align: center;
  }

  .message-hint-font-box span {
    font-size: 50px;
    color: black;
  }

  .message-hint-msg-box {
    margin-top: 20px;
    text-align: center;
    color: black;
    font-weight: bold;
  }

  .message-hint-skip-box {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-hint-skip-box button {
    cursor: pointer;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    width: 80px;
    height: 32px;
    border-radius: 5px;
  }
</style>
