<template>
  <div class="picture-base">
    <div class="picture-menu-box">
      <button @click="commonUtils.newSkip('/ai/picture_generation')">图片生成</button>
    </div>

  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  inject
} from 'vue';

const commonUtils = inject('$commonUtils');
const emits = defineEmits();

const device = ref(commonUtils.equipmentJudeg());
const windWidth = ref(window.innerWidth);
const windHeight = ref(window.innerHeight);
</script>

<style scoped>
  .picture-base{
    width: 100%;
  }

  .picture-menu-box{
    margin-top: 50px;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .picture-menu-box button{
    width: 120px;
    height: 100%;
    margin-right: 10px;
  }
</style>
