<template>
  <div class="home-base">
    <!--上栏-->
    <el-carousel class="home-trotting_horseLamp-box" :height="trottingHeight + 'px'" :type="trottingCard" :trigger="dropdownType" :interval="3000">
      <el-carousel-item v-for="(item, index) in trottingHorseLamp">
        <div class="home-trotting-box">
          <img class="home-trotting-img" v-bind:src="item" draggable="false" alt="" />
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 文章上选择框 -->
    <div class="home-subject-top-box">
      <button style="height: 100%" @click.stop="commonUtils.newSkip(`/article/browsing?aid=projectintroduction`)">项目介绍</button>
    </div>

    <div class="home-subject-box" :style="subjectStyle.subjectBox">
      <div class="home-subject-left-box">

        <div class="home-article-box" :style="subjectStyle.articleBox" v-for="(item, index) in articleList" @click.stop="commonUtils.newSkip(`/article/browsing?aid=${item.aid}`)">
          <div class="home-article-image-box" :style="subjectStyle.articleImageBox">
            <img :src="imagesPathApi + item.cover" draggable="false" alt="" />
          </div>
          <div class="home-article-content-box" :style="subjectStyle.articleContentBox">
            <div class="home-article-title">
              <h3 :style="subjectStyle.articleTitleH3">{{ item.title }}</h3>
            </div>
            <div class="home-article-details">
              <p :style="subjectStyle.articleDetailsP">{{ item.digest }}</p>
            </div>
            <div class="home-article-meun-box">
              <div class="home-article_user" :style="subjectStyle.articleUser" @click.stop>
                <span>作者：</span>
                <p>{{ item.nickname }}</p>
              </div>
              <div class="home-article-interaction-box" @click.stop>
                <span class="iconfont">&#xe8ad;</span>
                <span class="iconfont home-article-trample">&#xe8ad;</span>
                <span class="iconfont">&#xe607;</span>
              </div>
              <el-dropdown :trigger="dropdownType">
                <span class="home-el-dropdown-link" @click.stop>
                  <el-icon>
                    <MoreFilled />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>测试</el-dropdown-item>
                    <el-dropdown-item>测试</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>

      </div>

      <div ref="subjectRightRef" class="home-subject-right-box" :style="subjectStyle.rightBox">
        <div class="test">

        </div>
        <div class="test">

        </div>
        <!--备案号悬挂-->
        <div class="home-archival_information-box">
          <div class="home-archival_information">
            <p>ICP备案号：</p><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024044669号-1</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading1" class="home-loading1-box">
      <Loading1></Loading1>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs
  } from 'vue';
  import { useRouter } from 'vue-router';
  import {
    MoreFilled,
    StarFilled
  } from '@element-plus/icons-vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    dataInitialize();
    commonUtils.addPageIdentification(pageRandom);
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('scroll', handleBodyScroll);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('scroll', handleBodyScroll);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  //常量
  const router = useRouter();
  const cookie = inject('$cookie');
  const axiosApi = inject('$axiosApi');
  const commonUtils = inject('$commonUtils');
  const imagesPathApi = inject('$imagesPathApi');
  const pageRandom = 'Home-' + commonUtils.getRandomInteger(10) + commonUtils.getRandomString(5);

  // 变量
  let device = commonUtils.equipmentJudeg();
  let windWidth = window.innerWidth;
  let windHeight = window.innerHeight;
  let trottingCard = ref(null);
  let trottingHeight = ref(null);
  let dropdownType = ref(null);
  let trottingHorseLamp = ref([
    require('@/assets/image/trottingHorseLamp/1.jpg'),
    require('@/assets/image/trottingHorseLamp/4.jpg'),
    require('@/assets/image/trottingHorseLamp/5.jpg')
  ]);
  let articleList = ref([]);
  let limitOrigin = 0;
  let limitStrip = 10;
  let loading1 = ref(false);
  let articleJudeg = true;
  let subjectRightRef = ref(null);
  let subjectStyle = ref({
    subjectBox: { 'padding': null, 'width': null },
    articleBox: { 'margin-right': null, 'height': null, 'flex-direction': null, 'width': null },
    rightBox: { 'height': '100%', 'width': 0, 'min-width': 0, 'margin-left': null },
    articleImageBox: { 'width': null, 'height': null },
    articleContentBox: { 'width': null },
    articleTitleH3: { 'max-width': null, 'padding-left': null, 'padding-right': null },
    articleDetailsP: { 'width': null, 'height': null, 'padding': null },
    articleUser: { 'margin-left': null }
  });

  /**
   * 页面路由时执行
   */
  router.beforeEach((to, from, next) => {
    handleBeforeUnload();
    next();
  });

  /**
   * 页面刷新或关闭时执行
   */
  function handleBeforeUnload() {
    commonUtils.removePageIdentification(pageRandom);
  }

  /**
   * 监听浏览器页面是否可视
   */
  function visualizationMonitor() {
    commonUtils.addPageIdentification(pageRandom);
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    windHeight = window.innerHeight;
    device = commonUtils.equipmentJudeg();
    pageInitialize();
  }

  /**
   * 监听body滑动
   */
  function handleBodyScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight && articleJudeg) {
      gainArticleAxios(limitOrigin, limitStrip);
    } else {
      articleJudeg = true;
    }
    subjectRightRef.value.scrollTop = scrollTop - trottingHeight.value - 100;
  }

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    let subtrahend = 0;
    if (windWidth > 1920) {
      subtrahend = 5;
    } else if (windWidth >= 1000) {
      subtrahend = 0;
      trottingCard.value = 'card';
      subjectStyle.value.subjectBox['padding'] = '20px 100px';
      subjectStyle.value.subjectBox['width'] = 'calc(100% - 200px)';
      subjectStyle.value.articleBox['margin-right'] = '20px';
      subjectStyle.value.articleBox['height'] = '120px';
      subjectStyle.value.articleBox['flex-direction'] = '';
      subjectStyle.value.articleBox['width'] = '100%';
      subjectStyle.value.rightBox['width'] = '300px';
      subjectStyle.value.rightBox['min-width'] = '300px';
      subjectStyle.value.rightBox['margin-left'] = '20px';
      subjectStyle.value.articleImageBox['width'] = '180px';
      subjectStyle.value.articleImageBox['height'] = '120px';
      subjectStyle.value.articleContentBox['width'] = 'calc(100% - 180px)';
      subjectStyle.value.articleTitleH3['width'] = 'calc(100% - 20px)';
      subjectStyle.value.articleTitleH3['padding-left'] = '10px';
      subjectStyle.value.articleTitleH3['padding-right'] = '10px';
      subjectStyle.value.articleDetailsP['width'] = 'calc(100% - 21px)';
      subjectStyle.value.articleDetailsP['height'] = 'calc(100% - 21px)';
      subjectStyle.value.articleDetailsP['padding'] = '5px 10.5px';
      subjectStyle.value.articleUser['margin-left'] = '10px';
    } else {
      subtrahend = 2;
      trottingCard.value = null;
      subjectStyle.value.subjectBox['padding'] = '0';
      subjectStyle.value.subjectBox['width'] = '100%';
      subjectStyle.value.articleBox['margin-right'] = '0';
      subjectStyle.value.articleBox['height'] = 'auto';
      subjectStyle.value.articleBox['flex-direction'] = 'column';
      subjectStyle.value.articleBox['width'] = '90%';
      subjectStyle.value.rightBox['width'] = 0;
      subjectStyle.value.rightBox['min-width'] = 0;
      subjectStyle.value.rightBox['margin-left'] = '0';
      subjectStyle.value.articleImageBox['width'] = '95%';
      subjectStyle.value.articleImageBox['height'] = 'auto';
      subjectStyle.value.articleContentBox['width'] = '95%';
      subjectStyle.value.articleTitleH3['width'] = '100%';
      subjectStyle.value.articleTitleH3['padding-left'] = '0';
      subjectStyle.value.articleTitleH3['padding-right'] = '0';
      subjectStyle.value.articleDetailsP['width'] = '100%';
      subjectStyle.value.articleDetailsP['height'] = 'auto';
      subjectStyle.value.articleDetailsP['padding'] = '0';
      subjectStyle.value.articleUser['margin-left'] = '0';
    }
    dropdownType.value = device === "PC" ? 'click' : 'hover';
    trottingHeight.value = windWidth / (4 - subtrahend);
    subjectStyle.value.rightBox['height'] = windHeight - 20 + 'px';
  }

  /**
   * 页面初始化数据
   */
  function dataInitialize() {
    gainArticleAxios(limitOrigin, limitStrip);
  }

  /**
   * 分页获取所有已审核且不为私密文章文章
   */
  function gainArticleAxios() {
    loading1.value = true;
    const formData = new FormData();
    formData.append('auditState', '1');
    formData.append('limitOrigin', limitOrigin);
    formData.append('limitStrip', limitStrip);
    axiosApi.routine('/article/get_article_limit', 'POST', true, formData, false)
      .then(result => {
        let articleData = JSON.parse(result.data);
        articleData.forEach(item => {
          articleList.value.push(item)
        })
        limitOrigin += articleData.length;
        articleJudeg = false;
        loading1.value = false;
      }).catch(error => { loading1.value = false; });
  }
</script>

<style scoped>
  @import "@/assets/css/home.css";
</style>
