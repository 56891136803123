import {
  createRouter,
  createWebHistory
} from 'vue-router';
import { inject } from 'vue';
import Home from '@/views/Home.vue';
import AdminLogin from '@/views/admin/AdminLogin.vue';
import AdminBackstage from '@/views/admin/AdminBackstage.vue';
import UserRegister from '@/views/user/UserRegister.vue';
import UserLogin from '@/views/user/UserLogin.vue';
import Userinfo from '@/views/user/Userinfo.vue';
import ArticleBrowsing from '@/views/page/ArticleBrowsing.vue';
import Writing from '@/views/page/Writing.vue';
import Creation from '@/views/Creation.vue';
import Picture from '@/views/Picture.vue';
import ChatGpt from '@/views/ai/chatgpt/ChatGpt.vue';
import PictureGeneration from '@/views/ai/chatgpt/PictureGeneration.vue';
import Message from '@/views/page/Message.vue';
/*
showHeader 页面上栏开关
 */
const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Nuyoah', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: true }
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: AdminLogin,
    meta: { title: '管理登录', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/admin/backstage',
    name: 'adminBackstage',
    component: AdminBackstage,
    meta: { title: '后台管理', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/user/Register',
    name: 'userRegister',
    component: UserRegister,
    meta: { title: '注册', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/user/login',
    name: 'userLogin',
    component: UserLogin,
    meta: { title: '登录', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/user/userinfo',
    name: 'userinfo',
    component: Userinfo,
    meta: { title: '个人中心', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/article/browsing',
    name: 'articleBrowsing',
    component: ArticleBrowsing,
    meta: { title: '文章浏览', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: true }
  },
  {
    path: '/writing',
    name: 'writing',
    component: Writing,
    meta: { title: '写文章', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/creation',
    name: 'creation',
    component: Creation,
    meta: { title: '创作中心', favicon: '/static/images/logo/nuyoah_logo_black.png' }
  },
  {
    path: '/picture',
    name: 'picture',
    component: Picture,
    meta: { title: '图片', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: true }
  },
  {
    path: '/ai/chatgpt',
    name: 'chatGpt',
    component: ChatGpt,
    meta: { title: 'ChatGpt', favicon: '/static/images/logo/chatgpt_logo.png' }
  },
  {
    path: '/ai/picture_generation',
    name: 'pictureGeneration',
    component: PictureGeneration,
    meta: { title: 'Dall', favicon: '/static/images/logo/chatgpt_logo.png' }
  },
  {
    path: '/message',
    name: 'message',
    component: Message,
    meta: { title: '消息通知', favicon: '/static/images/logo/chatgpt_logo.png' }
  }
];

// 设置路由路径解析样式
const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes });
// 启动路由前设置标题与浏览器logo
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '';
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon.href = to.meta.favicon || '';
  }
  // 登录后跳转需要记录的路由
  const reuterArr = ['/', '/ai/chatgpt', '/ai/picture_generation', '/user/userinfo'];
  // 保存指定上一页路由
  reuterArr.forEach((r) => {
    if (r === from.fullPath) {
      inject('$routePath').value = from.fullPath;
    }
  });
  next();
});

export default router;
