<template>
  <Header v-if="showHeader"></Header>
  <router-view />
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    computed
  } from 'vue';
  import { useRoute } from 'vue-router';
  import Header from '@/components/layout/Header.vue';
  // 组件加载执行
  onMounted(() => {
    // 初始化页面
    pageInitialize();
    window.addEventListener('resize', handleBodyResize);
  });
  // 组件卸载执行
  onUnmounted(() => {
    // 移除页面大小变化监听
    window.removeEventListener('resize', handleBodyResize);
  });


  // 常用参数
  const route = useRoute();
  const commonUtils = inject('$commonUtils');
  const device = ref(commonUtils.equipmentJudeg());

  // 组件参数
  const showHeader = computed(() => route.meta.showHeader === true);

  // 页面初始化
  function pageInitialize(){
    handleBodyResize(true);
    // markdown样式
    commonUtils.loadCss('/static/dynamic_css/markdown_theme/hydrogen.css');
  }

  /**
   * 页面大小变化
   * @param {Object} judeg 是否为初次加载直接加载
   */
  function handleBodyResize(initializeJudeg) {
    if (device.value !== commonUtils.equipmentJudeg() || initializeJudeg) {
      device.value = commonUtils.equipmentJudeg();
      // 页面css
      commonUtils.whetherLoadCss('/static/dynamic_css/home-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/creation-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/page/writing-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/user/user_register-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/user/user_login-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/admin/admin_login-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/ai/chatgpt-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/user/userinfo-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/ai/picture_generation-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/subpage/creation-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/admin/admin_backstage-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/subpage/admin_backstage-pc.css');
      // 组件css
      commonUtils.whetherLoadCss('/static/dynamic_css/module/layout-pc.css');
      commonUtils.whetherLoadCss('/static/dynamic_css/module/toolkit-pc.css');
    }
  }
</script>

<style scoped lang="scss">
  @import "@/assets/css/app.css";
  @import "@/assets/iconfont/iconfont.css";
  // :deep() {
  //   @import "/public/static/dynamic_css/markdown_theme/hydrogen.css";
  // }
</style>
