// AES加解密工具
import CryptoJS from 'crypto-js';
import axiosApi from '@/assets/js/axiosApi.js';

const AESUtils = {
  /**
   * AES加密
   * @param {文本} text
   * @param {密钥名称} assign
   */
  async encrypt(text, assign) {
    return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(await this.getAeskey(assign)), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();
  },

  /**
   * AES解密
   * @param {密文} ciphertext
   * @param {密钥名称} assign
   */
  async decrypt(ciphertext, assign) {
    return CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(await this.getAeskey(assign)), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8);
  },

  /**
   * 获取AES密钥
   * @param {密钥名称} assign
   */
  getAeskey(assign) {
    return new Promise((resolve, reject) => {
      axiosApi.routine('/secureInterface/get_aeskey/' + assign, 'POST', true, new FormData, false)
        .then(result => {
          resolve(atob(result));
        }).catch(error => {
          reject(null);
          axiosApi.statusPopup(error);
        });
    });
  }
};

export default AESUtils;
