<template>
  <Loading v-if="loadingModule"></Loading>
  <div class="bms_login-base">
    <div class="bms_login-subject" :style="loginSubjectStyle">
      <div class="bms_login-subject-top">
        <img src="/static/images/logo/nuyoah_logo_black.png" alt="" draggable="false">
        <span>Nuyoah后台管理登录</span>
      </div>
      <div class="bms_login-subject-buttom">
        <form>
          <input class="bms_login-account" v-model="username" type="text" autocomplete="on" placeholder="账号" @keydown.enter="sendLoginAxios" />
          <div class="bms_login-password-box">
            <input class="bms_login-password" v-model="password" :type="accountPasswordType" autocomplete="on" placeholder="密码" @keydown.enter="sendLoginAxios" />
            <span class="iconfont bms_login-eyeSwitch" v-html="eyeSwitch" @click="eyeSwitchControl"></span>
          </div>
          <div class="bms_login-code-box">
            <input class="bms_login-code" v-model="code" type="text" placeholder="验证码" @keydown.enter="sendLoginAxios" />
            <img v-if="codeImage != null" :src="codeImage" alt="" draggable="false" @click="imageVerificationCodeAxios" />
          </div>
        </form>
      </div>
      <div class="bms_login-button">
        <button :disabled="loginButtonSwitch" @click="sendLoginAxios">登录</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  // 常用导入
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化页面
    pageInitialize();
    imageVerificationCodeAxios();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 常量
  const commonUtils = inject('$commonUtils');
  const cookie = inject('$cookie');
  const router = useRouter();
  const AESUtils = inject('$AESUtils');
  const axiosApi = inject('$axiosApi');

  // 变量
  let windWidth = window.innerWidth;
  let identification = commonUtils.getRandomString(5) + new Date().getTime();
  let codeImage = ref('/static/images/logo/nuyoah_logo_black.png');
  let eyeSwitch = ref('&#xe6fb;');
  let accountPasswordType = ref('password');
  let username = ref('');
  let password = ref('');
  let code = ref('');
  let loginButtonSwitch = ref(false);
  let loadingModule = ref(null);
  let loginSubjectStyle = ref({ 'width': null, 'border': null });

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    let subtrahend = 0;
    if (windWidth >= 800) {
      loginSubjectStyle.value['width'] = '600px';
      loginSubjectStyle.value['border'] = '1px solid rgba(75, 75, 75, 0.5)';
    } else {
      loginSubjectStyle.value['width'] = '100%';
      loginSubjectStyle.value['border'] = 'none';
    }
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 图片验证码请求
   */
  function imageVerificationCodeAxios() {
    const formData = new FormData();
    formData.append('identification', identification);
    axiosApi.routine('/user/code', 'POST', true, formData, false)
      .then(result => { codeImage.value = 'data:image/png;base64,' + result; })
      .catch(error => { axiosApi.statusPopup(error); });
  }

  /**
   * 密码显示开关
   */
  function eyeSwitchControl() {
    if (eyeSwitch.value === '&#xe6fb;') {
      eyeSwitch.value = '&#xe6f9;';
      accountPasswordType.value = 'text';
    } else {
      eyeSwitch.value = '&#xe6fb;';
      accountPasswordType.value = 'password';
    }
  }

  /**
   * 发送登录请求
   */
  async function sendLoginAxios() {
    console.log(username.value)
    if (!/^\s*$/.test(username.value) && !/^\s*$/.test(password.value)) {
      if (/^\s*$/.test(code.value)) {
        commonUtils.elmentMessage('验证码不为空', 'info', 20);
        return;
      }
      loginButtonSwitch.value = true;
      loadingModule.value = true;
      const formData = new FormData();
      formData.append('username', username.value);
      formData.append('password', await AESUtils.encrypt(password.value, 'stair'));
      formData.append('code', code.value);
      formData.append('identification', identification);
      axiosApi.routine('/admin/login', 'POST', true, formData, false, 'admin')
        .then(result => {
          if (result.status === '200') {
            commonUtils.thisSkip(router, '/admin/backstage');
            cookie.set('Short-Token', result.shortToken);
            cookie.set('Long-Token', result.longToken);
            cookie.set('Admininfo', encodeURI(JSON.stringify(result.userinfo)));
          } else if (result.status) {
            commonUtils.elmentMessage(result.hint, 'info', 20);
          } else {
            commonUtils.elmentMessage('未知错误', 'info', 20);
          }
          loginButtonSwitch.value = false;
          loadingModule.value = false;
          imageVerificationCodeAxios();
        })
        .catch(error => {
          loginButtonSwitch.value = false;
          loadingModule.value = false;
          axiosApi.statusPopup(error);
        });
    } else {
      commonUtils.elmentMessage('用户名或密码不为空', 'info', 20);
    }
  }
</script>

<style scoped>
  @import "@/assets/css/admin/admin_login.css";
</style>
