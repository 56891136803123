<!-- 上栏组件 -->
<template>
  <div class="Header-base" :style="HeaderStyle.shade" @click.stop>
    <div class="Header-logo-box" :style="HeaderStyle.logoBox">
      <div class="Header-logo" :style="HeaderStyle.gptRouter" @click.stop="homeSkip">
        <img src="/static/images/logo/nuyoah_logo_white.png" draggable="false" alt>
        <span>Nuyoah</span>
      </div>
      <div class="Header-menu-control-box" v-if="menuControl">
        <span class="iconfont Header-gpt-router-button" @click.stop="commonUtils.newSkip('/ai/chatgpt')">&#xe73a;</span>
        <div class="Header-top-close" @click.stop="MenuSwitch(true)">
          <span class="Header-span1" :style="HeaderStyle.menuSpan1"></span>
          <span class="Header-span2" :style="HeaderStyle.menuSpan2"></span>
        </div>
      </div>
    </div>
    <span class="Header-separate" v-if="!menuControl"></span>
    <div class="Header-menu-box" :style="HeaderStyle.menuButtonBox">
      <button :class="item.buttonClass" :style="HeaderStyle.menuButtons" v-for="(item, index) in routeButtons" @click.stop="routeSkip(item)">{{ item.buttonData }}</button>
    </div>
    <span class="Header-separate" v-if="!menuControl"></span>
    <div class="Header-gpt-button" v-if="!menuControl">
      <button @click.stop="commonUtils.newSkip('/ai/chatgpt')">CHATGPT</button>
    </div>
    <div class="Header-serch-box" :style="HeaderStyle.searchBox">
      <div class="Header-search" :style="HeaderStyle.search">
        <label for="search-box">
          <div class="Header-search-span">
            <span class="iconfont">&#xe60a;</span>
          </div>
        </label>
        <input id="search-box" type="text" />
      </div>
    </div>
    <div class="Header-head-box" :style="HeaderStyle.headBox">
      <img :src="userHead" @click="commonUtils.newSkip('/user/userinfo')" draggable="false" alt/>
      <button v-if="!userName" :style="HeaderStyle.closeBox" @click.stop="commonUtils.thisSkip(router, '/user/login')">登录</button>
      <button class="Header-close-box" v-if="userName" :style="HeaderStyle.closeBox">
        <span class="Header-s1" @click="commonUtils.newSkip('/user/userinfo')">{{ userName }}</span>
        <span class="Header-s2" @click.stop="commonUtils.userLogout">注销登录</span>
      </button>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    nextTick
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    userWhetherLogin();
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('scroll', handleBodyScroll);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('scroll', handleBodyScroll);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  // 常量
  const router = useRouter();
  const commonUtils = inject('$commonUtils');
  const resourceApi = inject('$resourceApi');
  const routeButtons = ref([
    { buttonData: '文章', buttonClass: 'routeButton', serial: '/prticle' },
    { buttonData: '图片', buttonClass: 'routeButton', serial: '/picture' },
    { buttonData: '视频', buttonClass: 'routeButton', serial: '/video' },
    { buttonData: '创作中心', buttonClass: 'routeButton', serial: '/creation' }
  ]);

  // 变量
  let windWidth = window.innerWidth;
  let userHead = ref(require('@/assets/image/system/user_head_default.png'));
  let userName = ref(null);
  let menuControl = false;
  let pageVariation = true;
  let menuMovement = true;

  // css变量
  let HeaderStyle = ref({
    shade: { 'top': 0, 'height': null, 'flex-direction': null, 'overflow-y': null, 'transition': 'all 0.3s ease-in-out' },
    logoBox: { 'width': null, 'min-width': null, 'justify-content': null },
    gptRouter: { 'margin-left': null },
    menuButtonBox: { 'width': null, 'height': null, 'display': null, 'justify-content': null, 'align-items': null },
    menuButtons: { 'width': null },
    headBox: { 'flex-direction': null, 'width': null, 'height': null, 'gap': null, 'margin-top': null, 'margin-bottom': null, 'padding-left': null },
    searchBox: { 'height': null },
    search: { 'width': null },
    menuSpan1: { 'transform': null, 'top': '40%' },
    menuSpan2: { 'transform': null, 'bottom': '40%' },
    closeBox: { 'margin-left': null }
  });

  /**
   * 判断用户是否登录
   */
  function userWhetherLogin() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      userHead.value = resourceApi + userinfo.head_portrait;
      userName.value = userinfo.nickname;
    }
  }

  /**
   * 监听页面变化
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 监听body滑动
   */
  function handleBodyScroll() {
    HeaderStyle.value.shade.transition = 'all 0.3s ease-in-out';
    const currentScrollPosition = window.scrollY || window.pageYOffset;
    if (currentScrollPosition > this.lastScrollPosition) {
      // 向下滑动
      if (currentScrollPosition >= 60) {
        HeaderStyle.value.shade.top = '-50px';
      }
    } else if (currentScrollPosition < this.lastScrollPosition) {
      // 向上滑动
      HeaderStyle.value.shade.top = 0;
    }
    this.lastScrollPosition = currentScrollPosition;
  }

  /**
   * 监听浏览器页面是否可视
   */
  function visualizationMonitor() {
    if (!document.hidden) {
      userWhetherLogin();
    }
  }

  /**
   * 页面初始化设置
   */
  function pageInitialize() {
    if (windWidth >= 800 && pageVariation) {
      if (menuMovement) {
        HeaderStyle.value.shade.transition = '';
      } else {
        HeaderStyle.value.shade.transition = 'all 0.3s ease-in-out';
      }
      menuControl = false;
      menuMovement = false;
      pageVariation = false;
      document.body.style.overflow = null;
      HeaderStyle.value.shade['overflow-y'] = 'hidden';
      HeaderStyle.value.shade.transition = '';
      HeaderStyle.value.shade.height = '50px';
      HeaderStyle.value.shade['flex-direction'] = '';
      HeaderStyle.value.logoBox.width = '122.8px';
      HeaderStyle.value.logoBox['min-width'] = '122.8px';
      HeaderStyle.value.logoBox['justify-content'] = 'center';
      HeaderStyle.value.gptRouter['margin-left'] = '10px';
      HeaderStyle.value.menuButtonBox.width = 'auto';
      HeaderStyle.value.menuButtonBox.height = '100%';
      HeaderStyle.value.menuButtonBox.display = '';
      HeaderStyle.value.menuButtonBox['justify-content'] = '';
      HeaderStyle.value.menuButtonBox['align-items'] = '';
      HeaderStyle.value.menuButtons.width = 'auto';
      HeaderStyle.value.headBox['flex-direction'] = '';
      HeaderStyle.value.headBox.width = '200px';
      HeaderStyle.value.headBox.height = '50px';
      HeaderStyle.value.headBox.gap = '';
      HeaderStyle.value.headBox['margin-top'] = '20px';
      HeaderStyle.value.headBox['margin-bottom'] = '20px';
      HeaderStyle.value.headBox['padding-left'] = '10px';
      HeaderStyle.value.search.width = '100%';
      HeaderStyle.value.searchBox.height = '50px';
      HeaderStyle.value.closeBox['margin-left'] = '12px';
    } else if (windWidth <= 800) {
      menuControl = true;
      menuMovement = true;
      pageVariation = true;
      HeaderStyle.value.shade['height'] = '50px';
      HeaderStyle.value.shade['flex-direction'] = 'column';
      HeaderStyle.value.shade['transition'] = 'all 0.3s ease-in-out';
      HeaderStyle.value.menuSpan1['transform'] = 'rotate(0)';
      HeaderStyle.value.menuSpan1['top'] = '40%';
      HeaderStyle.value.menuSpan2['bottom'] = '40%';
      HeaderStyle.value.menuSpan2['transform'] = 'rotate(0)';
      HeaderStyle.value.logoBox['width'] = '100%';
      HeaderStyle.value.logoBox['min-width'] = '100%';
      HeaderStyle.value.logoBox['justify-content'] = 'flex-start';
      HeaderStyle.value.gptRouter['margin-left'] = '20px';
      HeaderStyle.value.menuButtonBox['width'] = '50%';
      HeaderStyle.value.menuButtonBox['height'] = '50px';
      HeaderStyle.value.menuButtonBox['display'] = 'flex';
      HeaderStyle.value.menuButtonBox['justify-content'] = 'center';
      HeaderStyle.value.menuButtonBox['align-items'] = 'center';
      HeaderStyle.value.menuButtons['width'] = '100%';
      HeaderStyle.value.headBox['flex-direction'] = 'column';
      HeaderStyle.value.headBox['width'] = '100%';
      HeaderStyle.value.headBox['height'] = 'auto';
      HeaderStyle.value.headBox['gap'] = '10px';
      HeaderStyle.value.headBox['margin-top'] = '20px';
      HeaderStyle.value.headBox['margin-bottom'] = '20px';
      HeaderStyle.value.headBox['padding-left'] = '0';
      HeaderStyle.value.search['width'] = '80%';
      HeaderStyle.value.searchBox['height'] = '35px';
      HeaderStyle.value.closeBox['margin-left'] = 'auto';
    }
  }

  /**
   * 页面小于800开关控制
   * @param {判断是否只关闭} judeg
   */
  function MenuSwitch(judeg) {
    if (HeaderStyle.value.shade.height === '50px' && judeg) {
      document.body.style.overflow = 'hidden';
      HeaderStyle.value.shade.height = '100%';
      HeaderStyle.value.menuSpan1.top = 'auto';
      HeaderStyle.value.menuSpan2.bottom = 'auto';
      HeaderStyle.value.menuSpan1.transform = 'rotate(45deg)';
      HeaderStyle.value.menuSpan2.transform = 'rotate(-45deg)';
      HeaderStyle.value.shade['overflow-y'] = 'scroll';
    } else {
      document.body.style.overflow = null;
      HeaderStyle.value.shade.height = '50px';
      HeaderStyle.value.menuSpan1.top = '40%';
      HeaderStyle.value.menuSpan2.bottom = '40%';
      HeaderStyle.value.menuSpan1.transform = 'rotate(0)';
      HeaderStyle.value.menuSpan2.transform = 'rotate(0)';
      HeaderStyle.value.shade['overflow-y'] = 'hidden';
    }
  }

  /**
   * 路由跳转
   * @param {集合} item
   */
  function routeSkip(item) {
    MenuSwitch(false);
    commonUtils.thisSkip(router, item.serial)
    routeButtons.value.forEach(item => { item.buttonClass = 'routeButton'; });
    item.buttonClass = 'routeButtonActive';
  }

  /**
   * 主页路由跳转
   */
  function homeSkip() {
    MenuSwitch(false);
    commonUtils.thisSkip(router, "/")
    routeButtons.value.forEach(item => { item.buttonClass = 'routeButton'; });
  }
</script>

<style scoped>
  .Header-base {
    position: fixed;
    width: 100%;
    height: 50px;
    white-space: nowrap;
    background-color: black;
    display: flex;
    align-items: center;
    z-index: 2000;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .Header-separate {
    height: 50%;
    border: 1px solid #303133;
    border-radius: 5px;
  }

  .Header-logo-box {
    user-select: none;
    position: relative;
    width: 120px;
    min-width: 120px;
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
  }

  .Header-logo {
    margin-left: 10px;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .Header-logo img {
    width: 30px;
    height: 30px;
  }

  .Header-logo span {
    font-size: 16px;
    font-weight: bold;
  }

  .Header-menu-box {
    position: relative;
    width: auto;
    height: 100%;
  }

  .routeButton {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    height: 50px;
    color: white;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .routeButtonActive {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    height: 100%;
    color: white;
    border: none;
    background: red;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .Header-gpt-button {
    position: relative;
    margin-left: auto;
    min-width: 180px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header-gpt-button button {
    position: relative;
    width: 85%;
    height: 30px;
    border: none;
    background: none;
    color: white;
    border-radius: 2px;
    border: 1px solid white;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .Header-gpt-button button:active {
    width: 83%;
    height: 28px;
    background-color: #303133;
  }

  .Header-head-box {
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    width: 200px;
    min-width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .Header-head-box img {
    margin-left: 3px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .Header-head-box button {
    width: 112px;
    height: 30px;
    border: none;
    color: white;
    margin-right: auto;
    border-radius: 2px;
    background-color: #303133;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Header-head-box button:active {
    transition: none;
    box-shadow: inset 0 0 5px white;
  }

  .Header-serch-box {
    user-select: none;
    position: relative;
    width: 100%;
    min-width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header-search {
    position: relative;
    width: 100%;
    height: 35px;
    background: none;
    border: 1px solid #303133;
    display: flex;
    transition: border 0.3s ease-in-out;
  }

  .Header-search-span {
    cursor: pointer;
    position: absolute;
    min-width: 40px;
    height: 100%;
    background-color: #303133;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
  }

  .Header-search-span span {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    background: transparent;
    font-size: 23px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1998;
  }

  .Header-search-span span:active {
    font-size: 25px;
  }

  .Header-search input {
    padding-left: 45px;
    padding-right: 5px;
    position: relative;
    outline: none;
    border: 1px solid #303133;
    width: 100%;
    background: none;
    color: white;
    transition: border 0.2s ease-in-out;
    z-index: 1997;
  }

  .Header-search input:focus {
    border: 1px solid #909399;
  }

  .Header-menu-control-box {
    margin-right: 20px;
    position: relative;
    margin-left: auto;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header-gpt-router-button {
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
  }

  .Header-gpt-router-button:active {
    box-shadow: 0 0 10px white;
  }

  .Header-top-close {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header-top-close span {
    position: absolute;
    margin: 0;
    width: 20px;
    border: 1px solid white;
    background-color: white;
  }

  .Header-span1 {
    top: 40%;
    transition: all 0.3s ease-in-out;
  }

  .Header-span2 {
    bottom: 40%;
    transition: all 0.3s ease-in-out;
  }

  .Header-close-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .Header-s1 {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
  }

  .Header-s2 {
    width: 0;
    position: absolute;
    overflow: hidden;
    transition: none;
  }
</style>
