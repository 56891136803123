<!-- loading1加载组件 -->
<template>
  <div class="loading-1-sk-fading-circle" :style="loadingStyle">
    <div class="loading-1-sk-circle1 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle2 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle3 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle4 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle5 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle6 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle7 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle8 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle9 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle10 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle11 loading-1-sk-circle"></div>
    <div class="loading-1-sk-circle12 loading-1-sk-circle"></div>
  </div>
</template>

<script setup>
  import {
    ref,
    defineProps,
  } from 'vue';

  // 常量
  const props = defineProps({
    left: {
      type: String,
      required: false
    },
    right: {
      type: String,
      required: false
    },
    top: {
      type: String,
      required: false
    },
    bottom: {
      type: String,
      required: false
    },
    zIndex: {
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false
    }
  });

  // 位置变量
  let loadingStyle = ref({
    left: props.left ?? "50%",
    right: props.right ?? "auto",
    top: props.top ?? "50%",
    bottom: props.bottom ?? "auto",
    zIndex: props.zIndex ?? 2000,
  })
</script>

<style>
  .loading-1-sk-fading-circle {
    width: 25px;
    height: 25px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2000;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 12%;
    height: 12%;
    background-color: red;
    border-radius: 10px;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .loading-1-sk-fading-circle .loading-1-sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {

    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {

    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }
</style>
