<!-- 用户操作组件 -->
<template>
  <div class="user-base" @click.stop="suspension(false)" @touchstart.stop>
    <div class="user-suspension-box" v-if="userSuspension">
      <button @click.stop="commonUtils.newSkip('/userinfo')">
        <span class="iconfont">&#xe7bc;</span>设置中心
      </button>
      <button @click.stop="commonUtils.userLogout">
        <span class="iconfont">&#xe65a;</span>注销登录
      </button>
    </div>
    <div class="user-set-box" :style="userSetStyle" @click.stop="suspension(true)">
      <div class="user-head-box">
        <img :src="userHead" :style="userImage" draggable="false" alt="" />
      </div>
      <div class="user-name-box">
        <button v-if="!userName" :style="buttonStyle">登录</button>
        <button v-if="userName" :style="buttonStyle">{{ userName }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    userWhetherLogin();
    window.addEventListener('click', suspensionContor);
    window.addEventListener('touchstart', suspensionContor);
    window.addEventListener('visibilitychange', whetherBackstage);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('click', suspensionContor);
    window.removeEventListener('touchstart', suspensionContor);
    window.removeEventListener('visibilitychange', whetherBackstage);
  });

  // 常量
  const commonUtils = inject('$commonUtils');
  const router = useRouter();
  const resourceApi = inject('$resourceApi');

  // 变量
  let userHead = ref(require('@/assets/image/system/user_head_default.png'));
  let userName = ref(null);
  let userSuspension = ref(false);
  let buttonStyle = ref(null);
  let userSetStyle = ref(null);
  let userImage = ref(null);

  /**
   * 监听用户点击
   */
  function suspensionContor() {
    suspension(false);
  }

  /**
   * 监听浏览器页面是否可视
   */
  function whetherBackstage() {
    if (!document.hidden) {
      userWhetherLogin();
    }
  }

  /**
   * 判断用户是否登录，保存用户信息
   */
  function userWhetherLogin() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      userHead.value = resourceApi + userinfo.head_portrait;
      userName.value = userinfo.nickname;
    }
  }

  /**
   * 菜单栏开关控制
   * @param {是否只控制关闭} judeg
   */
  function suspension(judeg) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo !== false) {
      if (!userSuspension.value && judeg) {
        userSuspension.value = true;
        buttonStyle.value = { color: 'white' };
        userImage.value = { 'border-radius': '0' };
        userSetStyle.value = { 'background-color': 'black' };
      } else {
        userSuspension.value = false;
        buttonStyle.value = { color: 'black' };
        userImage.value = { 'border-radius': '100%' };
        userSetStyle.value = { 'background-color': '' };
      }
    } else {
      if(judeg){
        commonUtils.thisSkip(router, '/user/login');
      }
    }
  }
</script>

<style scoped>

  .user-base {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-set-box {
    position: relative;
    width: 50%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .user-suspension-box {
    position: absolute;
    width: 85%;
    height: auto;
    bottom: 50px;
    padding: 10px;
    border-radius: 5px;
    background-color: black;
    z-index: 1000;
  }

  .user-suspension-box span {
    font-size: 25px;
    margin-right: 10px;
  }

  .user-suspension-box button {
    width: 100%;
    height: 40px;
    border: none;
    background: none;
    outline: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 15px;
    border-radius: 5px;
  }

  .user-suspension-box:button {
    background-color: rgba(53, 59, 72, 0.8);
  }

  .user-head-box {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-head-box img {
    position: relative;
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 100%;
  }

  .user-name-box {
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .user-name-box button {
    font-size: 15px;
    position: relative;
    white-space: nowrap;
    max-width: 200px;
    height: 32px;
    background: none;
    border: none;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
