<template>
  <Inquiry v-if="inquiryData" :data="inquiryData" @inquiryEmits="inquiryMonitor"></Inquiry>
  <div class="creation-base">
    <!-- 上栏 -->
    <div class="creation-shade" v-if="shade" @click="leftSidebarSwitch(true)"></div>
    <div class="creation-top-box" :style="creationStyle.topBox">
      <div class="creation-left-sidebar-switch-box" v-if="leftSidebar">
        <span :style="creationStyle.sidebarSwitchBox" class="iconfont" @click="leftSidebarSwitch">&#xe60d;</span>
      </div>
      <div class="creation-logo-box" :style="creationStyle.logoBox" @click.stop="commonUtils.thisSkip(router, '/')">
        <img src="/static/images/logo/nuyoah_logo_black.png" draggable="false" alt>
        <span>Nuyoah</span>
      </div>
      <div class="creation-hint-box">
        <span>创作者中心</span>
      </div>
      <div class="creation-top-right-box" @click.stop="commonUtils.thisSkip(router, '/writing')">
        <div class="creation-issue-button-box">
          <span class="iconfont">&#xe716;</span>
          <p>创作</p>
        </div>
      </div>
    </div>
    <!-- 左栏 -->
    <div class="creation-left-box" :style="creationStyle.leftBox">
      <div class="creation-left-menu">
        <!-- 管理 -->
        <div class="creation-left-menu-box">
          <el-menu class="creation-element-menu" :default-openeds="['1']" :default-active="menuActiveIndex" @select="menuHandleSelect">
            <el-sub-menu index="1">
              <template #title>
                <el-icon class="creation-left-menu-icon">
                  <TrendCharts />
                </el-icon>
                <span>管理</span>
              </template>
              <el-menu-item index="m-1" @click="menuControl('contentManagement')">内容管理</el-menu-item>
              <el-menu-item index="m-2" @click="menuControl('')">评论管理</el-menu-item>
              <el-menu-item index="m-3" @click="menuControl('')">专栏管理</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
        <!-- 数据 -->
        <div class="creation-left-menu-box">
          <el-menu class="creation-element-menu" :default-openeds="['1']" :default-active="menuActiveIndex" @select="menuHandleSelect">
            <el-sub-menu index="1">
              <template #title>
                <el-icon class="creation-left-menu-icon">
                  <Histogram />
                </el-icon>
                <span>数据</span>
              </template>
              <el-menu-item index="d-1" @click="menuControl('')">作品数据</el-menu-item>
              <el-menu-item index="d-2" @click="menuControl('')">收益数据</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="creation-right-box" :style="creationStyle.rightBox">
      <div class="creation-right-subject-box">
        <ContentManagement ref="contentManagementRef" v-if="subpage === 'contentManagement'" @contentManagementEmits="contentManagementMonitor"></ContentManagement>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import {
    TrendCharts,
    Histogram
  } from '@element-plus/icons-vue';
  import { useRouter } from 'vue-router';
  import ContentManagement from '@/views/subpage/creation/ContentManagement.vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化页面
    pageInitialize();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 常量
  const router = useRouter();
  const commonUtils = inject('$commonUtils');

  //变量
  let windWidth = window.innerWidth;
  let menuActiveIndex = ref(null);
  let shade = ref(false);
  let subpage = ref(null);
  let inquiryData = ref(null);
  let contentManagementRef = ref(null);
  let leftSidebar = ref(false);
  let pageVariation = false;
  let creationStyle = ref({
    topBox: { 'justify-content': null },
    logoBox: { 'margin-left': null, 'width': null },
    leftBox: { 'width': null, 'min-width': null, 'transition': null, 'background-color': null },
    sidebarSwitchBox: { 'color': 'black' },
    rightBox: { 'margin-left': null, 'width': null, 'padding-top': null, 'padding-bottom': null }
  })

  /**
   * 页面初始化
   */
  function pageInitialize() {
    creationStyle.value.leftBox['transition'] = 'none';
    if (windWidth >= 1000 && pageVariation) {
      shade.value = false;
      pageVariation = false;
      leftSidebar.value = false;
      creationStyle.value.topBox['justify-content'] = '';
      creationStyle.value.logoBox['margin-left'] = '30px';
      creationStyle.value.logoBox['width'] = '120px';
      creationStyle.value.leftBox['width'] = '250px';
      creationStyle.value.leftBox['min-width'] = '250px';
      creationStyle.value.leftBox['background-color'] = '';
      creationStyle.value.sidebarSwitchBox['color'] = 'red';
      creationStyle.value.rightBox['margin-left'] = '250px';
      creationStyle.value.rightBox['width'] = 'calc(100% - 350px)';
      creationStyle.value.rightBox['padding-top'] = '80px';
      creationStyle.value.rightBox['padding-bottom'] = '30px';
    } else if (windWidth <= 1000 && !pageVariation) {
      pageVariation = true;
      leftSidebar.value = true;
      creationStyle.value.topBox['justify-content'] = 'center';
      creationStyle.value.logoBox['margin-left'] = '0';
      creationStyle.value.logoBox['width'] = 'auto';
      creationStyle.value.leftBox['width'] = '0';
      creationStyle.value.leftBox['min-width'] = '0';
      creationStyle.value.leftBox['background-color'] = 'white';
      creationStyle.value.sidebarSwitchBox['color'] = 'black';
      creationStyle.value.rightBox['margin-left'] = '0';
      creationStyle.value.rightBox['width'] = '100%';
      creationStyle.value.rightBox['padding-top'] = '50px';
      creationStyle.value.rightBox['padding-bottom'] = '0';
    }
  }

  /**
   * 左侧栏控制开关
   * @param {boolean} closeJudeg 是否只控制关闭
   */
  function leftSidebarSwitch(closeJudeg) {
    creationStyle.value.leftBox['transition'] = 'width 0.3s ease-in-out';
    if (windWidth <= 1000) {
      if (creationStyle.value.leftBox['width'] === '0' && closeJudeg !== true) {
        shade.value = true;
        creationStyle.value.leftBox['width'] = '240px';
        creationStyle.value.sidebarSwitchBox['color'] = 'red';
      } else {
        shade.value = false;
        creationStyle.value.leftBox['width'] = '0';
        creationStyle.value.sidebarSwitchBox['color'] = 'black';
      }
    }
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 左侧栏菜子单点击逻辑
   * @param {Object} index 子菜单唯一标识
   */
  function menuHandleSelect(index) {
    menuActiveIndex.value = index;
  }

  /**
   * 控制左侧栏菜单开关
   * @param {Object} name 菜单名称
   */
  function menuControl(name) {
    subpage = name;
    leftSidebarSwitch(true);
  }

  /**
   * 监听删除文章
   */
  function contentManagementMonitor(params) {
    inquiryData.value = {
      aid: params.aid,
      message: params.message
    }
  }

  /**
   * 信息提示框回调
   * @param {JSON} params 子组件传值
   */
  function inquiryMonitor(params) {
    if (params.judeg) {
      contentManagementRef.value.deleteArtic(params.data.aid);
    }
    inquiryData.value = null;
  }
</script>

<style scoped>
  @import "@/assets/css/creation.css";
</style>
