<template>
  <Inquiry v-if="inquiryData" :data="inquiryData" @inquiryEmits="inquiryMonitor"></Inquiry>
  <div class="administrator_page-base">
    <div class="administrator_page-shade" v-if="shade" @click="leftSidebarSwitch(true)"></div>
    <div class="administrator_page-top" :style="administratorPageStyle.top">
      <div class="administrator_page-left-sidebar-switch-box" v-if="leftSidebar">
        <span class="iconfont" :style="administratorPageStyle.sidebarSwitchBox" @click="leftSidebarSwitch">&#xe60d;</span>
      </div>
      <div class="administrator_page-logo-box" :style="administratorPageStyle.logoBox">
        <img src="/static/images/logo/nuyoah_logo_white.png" draggable="false" alt>
        <span>Nuyoah</span>
      </div>
      <div class="administrator_page-hint-box">
        <span>后台管理中心</span>
      </div>
      <div class="administrator_page-admininfo-box">
        <el-dropdown class="administrator_page-admininfo-dropdown">
          <el-button type="primary">{{ adminNickname }}</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="adminLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="administrator_page-left" :style="administratorPageStyle.left">
      <div class="administrator_page-left-menu">
        <!-- 用户管理 -->
        <div class="administrator_page-left-menu-box">
          <el-menu class="administrator_page-element-menu" :default-active="menuActiveIndex" @select="menuHandleSelect">
            <el-sub-menu index="1">
              <template #title>
                <el-icon class="administrator_page-left-menu-icon">
                  <UserFilled />
                </el-icon>
                <span>用户管理</span>
              </template>
              <el-menu-item index="u-1" @click="menuControl('')">账号管理</el-menu-item>
              <el-menu-item index="u-2" @click="menuControl('')">权限管理</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
        <!-- 文章管理 -->
        <div class="administrator_page-left-menu-box">
          <el-menu class="administrator_page-element-menu" :default-active="menuActiveIndex" @select="menuHandleSelect">
            <el-sub-menu index="1">
              <template #title>
                <el-icon class="administrator_page-left-menu-icon">
                  <TrendCharts />
                </el-icon>
                <span>文章管理</span>
              </template>
              <el-menu-item index="a-1" @click="menuControl('articleManagement')">内容管理</el-menu-item>
              <el-menu-item index="a-2" @click="menuControl('')">评论管理</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="administrator_page-right" :style="administratorPageStyle.right">
      <ArticleManagement ref="articleManagementRef" v-if="subpage === 'articleManagement'" @articMangementEmits="articMangementMonitor"></ArticleManagement>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import {
    UserFilled,
    TrendCharts
  } from '@element-plus/icons-vue';
  import { useRouter } from 'vue-router';
  import ArticleManagement from '@/views/subpage/admin_backstage/ArticleManagement.vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化页面
    pageInitialize();
    AdminWhetherLogin();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 常量
  const commonUtils = inject('$commonUtils');
  const router = useRouter();
  const cookie = inject('$cookie');

  // 变量
  let windWidth = window.innerWidth;
  let shade = ref(false);
  let menuActiveIndex = ref(null);
  let adminNickname = ref('管理员');
  let subpage = ref(null);
  let inquiryData = ref(null);
  let articleManagementRef = ref(null);
  let leftSidebar = ref(false);
  let pageVariation = false;
  let administratorPageStyle = ref({
    top: { 'justify-content': null },
    logoBox: { 'margin-left': null, 'width': null },
    left: { 'width': '240px', 'transition': null },
    sidebarSwitchBox: { 'color': 'white' },
    right: { 'margin-left': null, 'width': null }
  })

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    administratorPageStyle.value.left['transition'] = 'none';
    if (windWidth >= 1000 && pageVariation) {
      shade.value = false;
      pageVariation = false;
      leftSidebar.value = false;
      administratorPageStyle.value.top['justify-content'] = '';
      administratorPageStyle.value.logoBox['margin-left'] = '30px';
      administratorPageStyle.value.logoBox['width'] = '120px';
      administratorPageStyle.value.left['width'] = '240px';
      administratorPageStyle.value.sidebarSwitchBox['color'] = 'blue';
      administratorPageStyle.value.right['margin-left'] = '240px';
      administratorPageStyle.value.right['width'] = 'calc(100% - 240px)';
    } else if (windWidth <= 1000 && !pageVariation) {
      pageVariation = true;
      leftSidebar.value = true;
      administratorPageStyle.value.top['justify-content'] = 'center';
      administratorPageStyle.value.logoBox['margin-left'] = '0';
      administratorPageStyle.value.logoBox['width'] = 'auto';
      administratorPageStyle.value.left['width'] = '0';
      administratorPageStyle.value.sidebarSwitchBox['color'] = 'white';
      administratorPageStyle.value.right['margin-left'] = '0';
      administratorPageStyle.value.right['width'] = '100%';
    }
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    pageInitialize();
  }

  /**
   * 登录判断
   */
  function AdminWhetherLogin() {
    let admininfo = commonUtils.emptyCookie('Admininfo', null, null);
    if (admininfo) {
      adminNickname.value = admininfo.nickname;
    } else {
      commonUtils.thisSkip(router, '/bms_login');
    }
  }


  /**
   * 退出登录
   */
  function adminLogout() {
    cookie.remove('Admininfo');
    commonUtils.thisSkip(router, '/bms_login');
  }

  /**
   * 控制左侧栏菜单开关
   * @param {Object} name 菜单名称
   */
  function menuControl(name) {
    subpage = name;
    leftSidebarSwitch(true);
  }

  /**
   * 左侧栏菜子单点击逻辑
   * @param {Object} index 子菜单唯一标识
   */
  function menuHandleSelect(index) {
    menuActiveIndex.value = index;
  }

  /**
   * 左侧栏控制开关
   * @param {boolean} closeJudeg 是否只控制关闭
   */
  function leftSidebarSwitch(closeJudeg) {
    administratorPageStyle.value.left['transition'] = 'width 0.3s ease-in-out';
    if (windWidth <= 1000) {
      if (administratorPageStyle.value.left['width'] === '0' && closeJudeg !== true) {
        shade.value = true;
        administratorPageStyle.value.left['width'] = '240px';
        administratorPageStyle.value.sidebarSwitchBox['color'] = 'rgba(0, 168, 255,1.0)';
      } else {
        shade.value = false;
        administratorPageStyle.value.left['width'] = '0';
        administratorPageStyle.value.sidebarSwitchBox['color'] = 'white';
      }
    }
  }

  /**
   * 监听文章审核
   */
  function articMangementMonitor(params) {
    inquiryData.value = {
      aid: params.aid,
      state: params.state,
      message: params.message
    }
  }

  /**
   * 信息提示框回调
   * @param {JSON} params 子组件传值
   */
  function inquiryMonitor(params) {
    if (params.judeg) {
      articleManagementRef.value.auditAxios(params.data.aid, params.data.state);
    }
    inquiryData.value = null;
  }
</script>

<style scoped>
  @import "@/assets/css/admin/admin_backstage.css";
</style>
