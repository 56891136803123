<template>
  <div class="article_mangement-base" v-loading="loading" element-loading-background="rgba(255, 255, 255,0.3)">
    <div class="article_mangement-top-box">
    </div>
    <div class="article_mangement-centre-box">

      <div class="article_mangement-list-box" v-for="(item, index) in articleList">
        <div class="article_mangement-list-left-box">
          <img :src="imagesPathApi + item.cover" alt=""/>
        </div>
        <div class="article_mangement-list-right-box">
          <div class="article_mangement-list-top">
            <div class="article_mangement-list-top-left">
              <span class="article_mangement-list-title">{{ item.title }}</span>
            </div>
            <div class="article_mangement-list-top-right">
              <span class="article_mangement-list-timestamp">{{ item.timestamp }}</span>
            </div>
          </div>
          <div class="article_mangement-list-bottom">
            <span>作者：{{ item.nickname }}</span>
            <div class="article_mangement-list-audit-box">
              <Dropdown :control="dropdownControl" :data="{normalcyColor: 'rgba(75, 75, 75,0.7)', dynamicColor: 'rgba(75, 75, 75,1.0)', fontSize: '15px'}">
                <template v-slot:menus>
                  <button @click.stop="commonUtils.newSkip(`/article/browsing?aid=${item.aid}`)">浏览</button>
                  <button @click.stop="auditFailure(item)">驳回</button>
                  <button @click.stop="successfulAudit(item)">放行</button>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="article_mangement-bottom-box">
      <Paging ref="pagingRef" :sum="pagingSum" @pagingEmits="pagingMonitor"></Paging>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    defineEmits,
    defineExpose
  } from 'vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    dataInitialize();
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {

  });

  //常量
  const emits = defineEmits();
  const axiosApi = inject('$axiosApi');
  const commonUtils = inject('$commonUtils');
  const imagesPathApi = inject('$imagesPathApi');

  // 变量
  let device = commonUtils.equipmentJudeg();
  let dropdownControl = ref(device.value === 'PC' ? 'hover' : 'click');
  let pagingRef = ref(null);
  let pagingSum = ref(0);
  let articleList = ref([]);
  let loading = ref(false);

  /**
   * 暴露方法给父类
   */
  defineExpose({ auditAxios });

  /**
   * 页面初始化
   */
  function dataInitialize() {
    gainArticleAxios(pagingRef.value.pagingLast, pagingRef.value.strip);
  }

  /**
   * 分页获取所有文章
   * @param {Object} limitOrigin
   * @param {Object} limitStrip
   */
  function gainArticleAxios(limitOrigin, limitStrip) {
    loading.value = true;
    const formData = new FormData();
    formData.append('auditState', '0');
    formData.append('limitOrigin', limitOrigin * limitStrip);
    formData.append('limitStrip', limitStrip);
    axiosApi.routine('/article/get_article_limit', 'POST', true, formData, true, 'admin')
      .then(result => {
        pagingSum.value = result.sum;
        articleList.value = JSON.parse(result.data);
        loading.value = false;
      }).catch(error => { loading.value = false; });
  }

  /**
   * 监听分页传值
   */
  function pagingMonitor(params) {
    gainArticleAxios(params.limitOrigin, params.limitStrip);
  }

  /**
   * 审核通过
   * @param {Object} item 文章信息
   */
  function successfulAudit(item) {
    emits('articMangementEmits', {
      judeg: true,
      state: 1,
      aid: item.aid,
      message: "是标记审核通过？"
    });
  }

  /**
   * 审核不通过
   * @param {Object} item 文章信息
   */
  function auditFailure(item) {
    emits('articMangementEmits', {
      judeg: true,
      state: 2,
      aid: item.aid,
      message: "是标记审核不通过？"
    });
  }


  /**
   * 文章审核
   * @param {Object} aid 文章id
   * @param {Object} state 审核状态
   */
  function auditAxios(aid, state) {
    const formData = new FormData();
    formData.append('aid', aid);
    formData.append('state', state);
    axiosApi.routine('/article/audit', 'POST', true, formData, true, 'admin')
      .then(result => {
        if (result === 1) {
          pagingRef.value.paginationJudeg = true;
          gainArticleAxios(pagingRef.value.pagingLast - 1, pagingRef.value.strip);
          commonUtils.elmentMessage('审核成功', 'info', 60);
        } else {
          commonUtils.elmentMessage('审核失败', 'error', 60);
        }
      }).catch(error => { commonUtils.elmentMessage('审核异常' + error, 'error', 60); });
  }
</script>

<style scoped>
  .article_mangement-base {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .article_mangement-top-box {
    height: 100px;
  }

  .article_mangement-centre-box {
    height: calc(100% - 180px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .article_mangement-centre-box::-webkit-scrollbar {
    display: none;
  }

  .article_mangement-bottom-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .article_mangement-list-box {
    position: relative;
    width: 95%;
    height: 70px;
    margin: 10px auto 10px auto;
    border-bottom: 1px solid rgba(164, 176, 190, 0.5);
    display: flex;
  }

  .article_mangement-list-top {
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
  }

  .article_mangement-list-top-left{
    width: 50%;
    display: flex;
    align-items: center;
  }

  .article_mangement-list-top-right{
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
  }

  .article_mangement-list-timestamp{
    white-space: nowrap;
    margin-left: auto;
    margin-right: 80px;
    font-size: 12px;
    color: gray;
  }

  .article_mangement-list-title {
    margin-left: 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(87, 96, 111, 1.0);
  }

  .article_mangement-list-bottom {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .article_mangement-list-bottom span {
    margin-left: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: gray;
    cursor: pointer;
  }

  .article_mangement-list-audit-box {
    margin-left: auto;
    margin-right: 30px;
  }

  .article_mangement-list-audit-box button {
    user-select: none;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    background: none;
    font-size: 13px;
    cursor: pointer;
    color: rgba(87, 96, 111, 1.0);
    transition: color 0.1s ease-in-out;
  }

  .article_mangement-list-left-box{
    position: relative;
    width: 120px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .article_mangement-list-left-box img{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    /* object-fit: contain; */
  }

  .article_mangement-list-right-box{
    width: calc(100% - 120px);
  }
</style>
