<!-- Markdown编辑器组件 -->
<template>
  <div class="markdown-editor-base">
    <!-- 工具菜单栏 -->
    <div class="markdown-editor-tool-menu-box">
      <div class="markdown-editor-tool-left-box">

        <!-- 标题键 -->
        <div class="markdown-editor-outer-box">
          <div class="markdown-editor-within-box">
            <el-tooltip class="markdown-editor-elment-tooltip" content="标题" placement="top" :hide-after="0">
              <el-button>
                <el-dropdown class="markdown-editor-elment-menu-box" placement="bottom">
                  <el-button><span class="iconfont markdown-editor-icon">&#xe6d1;</span></el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item class="iconfont">&#xe700; 一级标题</el-dropdown-item>
                      <el-dropdown-item class="iconfont">&#xe9e2; 二级标题</el-dropdown-item>
                      <el-dropdown-item class="iconfont">&#xe9e3; 三级标题</el-dropdown-item>
                      <el-dropdown-item class="iconfont">&#xe786; 四级标题</el-dropdown-item>
                      <el-dropdown-item class="iconfont">&#xe9e4; 五级标题</el-dropdown-item>
                      <el-dropdown-item class="iconfont">&#xe9e5; 六级标题</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-button>
            </el-tooltip>
          </div>
        </div>

        <!-- 加粗键 -->
        <div class="markdown-editor-outer-box">
          <div class="markdown-editor-within-box">
            <el-tooltip class="markdown-editor-elment-tooltip" content="加粗" placement="top" :hide-after="0">
              <el-button><span class="iconfont markdown-editor-icon">&#xe6cb;</span></el-button>
            </el-tooltip>
          </div>
        </div>

        <button @click="test">测试</button>
      </div>
    </div>
    <!-- 编辑区 -->
    <div class="markdown-editor-compile-box">
      <div ref="compileRef" class="markdown-editor-compile-write" contenteditable="true" :style="compileStyle" placeholder="样式手册" @click="cursorSet" @scroll="compileSlideMoniter" @blur="updataCursorLocation" @input="compileMonitor" @compositionstart="spellStart" @compositionend="spellEnd" @keydown="conmplieMoniterKeyboard" @cut="customCut" @paste="customPaste">
        <div class="markdown-paragraph"><span class="markdown-br"><br></span></div>
      </div>
      <div ref="previewRef" class="markdown-editor-complie-preview" @scroll="previewSlideMoniter">
        <div class="markdown-body" v-html="previewContent"></div>
      </div>
    </div>
    <!-- 低栏计数 -->
    <div class="markdown-editor-record-box">

    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    nextTick,
    defineExpose
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化Mermaid配置
    nextTick(() => {
      mermaid.initialize({
        startOnLoad: true,
        suppressErrorRendering: true,
        securityLevel: 'loose',
        theme: 'neutral'
      });
    });
    window.addEventListener('resize', handleBodyResize);
    document.addEventListener('selectionchange', handleSelectionChange);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.addEventListener('resize', handleBodyResize);
    document.removeEventListener('selectionchange', handleSelectionChange);
  })

  // 常量
  const emits = defineEmits();
  const router = useRouter();
  const commonUtils = inject('$commonUtils');
  const mermaid = inject('$mermaid');

  // 变量
  let windHeight = window.innerHeight;
  let compileRef = ref();
  let compileContent = ref('');
  let previewRef = ref();
  let previewContent = ref('');
  let caretOffset = 0;
  let selectionOffset = null;
  let contextOffset = null;
  let cutOffset = null;
  let pasteOffset = null;
  let blankLineOffset = null;
  let mergeOffset = null;
  let isComposing = false;
  let keydown = null;
  let posType = null;
  let compileStyle = ref({ 'padding': '20px 20px 40vh 20px' });

  /**
   * 暴露输入数据给父类
   */
  defineExpose({ compileContent });


  /**
   * 监听窗口变化
   */
  function handleBodyResize() {
    windHeight = window.innerHeight;
    compileStyle.value.padding = '20px 20px ' + windHeight * 0.05 + 'vh 20px';
  }

  /**
   * 同步滑动监听编辑区与预览区
   */
  function compileSlideMoniter() {
    previewRef.value.scrollTop = compileRef.value.scrollTop;
  }

  function previewSlideMoniter() {
    compileRef.value.scrollTop = previewRef.value.scrollTop;
  }

  /**
   * 拼音输入开始
   */
  function spellStart() {
    isComposing = true;
  }

  /**
   * 拼音输入结束
   */
  function spellEnd(event) {
    isComposing = false;
    compileMonitor();
  }

  /**
   * 记录编辑器光标位置
   */
  function updataCursorLocation() {
    // cursorPosStart = saveCaretPosition(compileRef.value);
  }


  /**
   * 处理编辑器粘贴
   * @param {Object} event
   */
  function customPaste(event) {
    let selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0);
      let node = range.startContainer;
      if (node.nodeType === Node.ELEMENT_NODE) {
        let paragraph = node.closest('.markdown-paragraph').previousElementSibling;
        if (paragraph && paragraph.innerText !== '\n') {
          pasteOffset = true;
        }
      }
    }
  }

  /**
   * 处理编辑框剪切
   * @param {Object} event
   */
  function customCut(event) {
    let selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0);
      let node = range.startContainer;
      if (node.nodeType === Node.TEXT_NODE) {
        let paragraph = node.parentNode.closest('.markdown-paragraph');
        if (paragraph.innerText.includes('\n') && range.startOffset === 0 && node.textContent.length === range.endOffset) {
          cutOffset = true;
        }
      }
    }
  }

  /**
   * 监听选区
   */
  function handleSelectionChange() {
    // 处理编辑器上下文
    let selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let node = range.startContainer;
      if (compileRef.value && compileRef.value.contains(node)) {
        if (node.nodeType === Node.TEXT_NODE) {
          let paragraph = compileRef.value.querySelector('.markdown-paragraph');
          let paragraphSon = paragraph.firstChild.firstChild;
          contextOffset = !node.nextElementSibling;
          let selectionJudeg = false;
          // 是否全选文本节点
          if (range.startOffset === 0 && node.textContent.length === range.endOffset) {
            selectionOffset = true;
            selectionJudeg = true;
          } else {
            selectionOffset = false;
            selectionJudeg = false;
          }
          // 是否需要插入空行
          if (paragraph.innerText.includes('\n') && (selectionJudeg || node.textContent.length === 1)) {
            if (paragraphSon && node === paragraphSon) {
              blankLineOffset = true;
            }
          } else {
            blankLineOffset = false;
          }
          // 段落合并判断
          let parentNode = node.parentNode.closest('.markdown-paragraph');
          if (parentNode &&
            parentNode.previousElementSibling &&
            parentNode.previousElementSibling.previousElementSibling &&
            parentNode.previousElementSibling.innerText === '\n' &&
            parentNode.previousElementSibling.previousElementSibling.innerText !== '\n' &&
            range.startOffset === 0) {
            mergeOffset = true;
          } else {
            mergeOffset = false;
          }
        }
      }
    }
    cutOffset = false; // 重置剪切判定
    pasteOffset = false; // 重置粘贴判定
  }

  /**
   * 编辑器光标操作
   */
  function cursorSet(event) {
    if (event) {
      // gainParagraphSet(event.target);
      // console.log(JSON.stringify(markdownPRange.innerText))
      // saveCaretPosition();
      // console.log(cursorPosStart)
      // console.log(cursorPosEnd)
      // restoreCaretPosition(markdownPRange, cursorPosStart, cursorPosEnd);
    }
  }


  /**
   * 编辑框监听按钮
   */
  function conmplieMoniterKeyboard(event) {
    keydown = event.key;
  }

  /**
   * 编辑框输入监听
   */
  function compileMonitor() {
    compileContent.value = compileRef.value.innerText;
    if (!isComposing) {
      let randM = commonUtils.getRandomString(10) + new Date().getTime();
      let mdData = commonUtils.markdownit(compileContent.value, randM, 'compile');
      if (mdData !== null) {
        previewContent.value = mdData.content;
        nextTick(async () => {
          try {
            await mermaid.run();
          } catch (__) {}
        });
      }
      saveCaretPosition(compileRef.value);
      matchingMarkdown();
      restoreCaretPosition(compileRef.value, caretOffset);
    }
  }

  /**
   * 编辑框内文本进行markdonw样式封装
   */
  function matchingMarkdown() {
    // //获取编辑器下所有段落
    let markdownText = compileContent.value;
    let br = '<span class="markdown-br"><br></span>';
    let blankLine = '<div class="markdown-paragraph"><span class="markdown-br"><br></span></div>';
    let markdownParagraph = compileRef.value.querySelectorAll('.markdown-paragraph');
    // 保持编辑器始终有一个段落
    if (markdownParagraph.length === 0) {
      compileRef.value.innerHTML = blankLine;
    } else {
      // 对段落进行文本封装与组合
      let paragraphText = '';
      let paragraphArr = [];
      for (let i = 0; i < markdownText.length; i++) {
        // 判断是否需要格式化文本
        let prepositionData = matchingPreposition(i, markdownText);
        let judeg = prepositionData.judeg;
        if (judeg) {
          let label = prepositionData.label;
          let vlaue = prepositionData.vlaue;
          let data = matchingSymbolFindPreposition(i, markdownText, label, vlaue);
          if (data.judeg) {
            if (paragraphText) paragraphArr.push(matchingSymbolFindPostposition(paragraphText));
            paragraphArr.push(data.text);
            i = data.index;
            judeg = false;
            continue;
          }
        }
        // 处理格式化后的文本
        if (markdownText.slice(i, i + 2) === '\n\n') {
          if (paragraphText) paragraphArr.push(matchingSymbolFindPostposition(paragraphText));
          paragraphArr.push(blankLine);
          paragraphText = '';
          i++;
          continue;
        } else {
          paragraphText += markdownText[i];
        }
        if (i === markdownText.length - 1) {
          if (paragraphText !== '\n') {
            paragraphArr.push(matchingSymbolFindPostposition(paragraphText));
          } else {
            paragraphArr.push(blankLine);
          }
        }
      }
      compileRef.value.innerHTML = paragraphArr.join('').replace(/\n/g, br);
    }
  }

  /**
   * 判断是否需要格式化文本
   * @param {Object} i 文本开始下标
   * @param {Object} text 文本
   */
  function matchingPreposition(i, text) {
    let data = { judeg: false, lable: null, value: null };
    for (let j = 6; j >= 1; j--) {
      let H = '#'.repeat(j) + ' ';
      if (text.slice(i, i + j + 1) === H) {
        data.judeg = true;
        data.label = 'h';
        data.vlaue = j;
        break;
      }
    }
    return data;
  }

  /**
   * 符号配对
   * @param {int} index 索引
   * @param {string} str 字符串
   * @param {boolean} oneLineJudeg 单行判断
   * @param {boolean} twoLineJudeg 双行判断
   */
  function matchingSymbolFindPreposition(index, str, label, vlaue) {
    let data = { index: 0, text: '', judeg: false };
    switch (label) {
      case 'h':
        for (let i = index; i < str.length; i++) {
          data.text += str[i];
          if ((!str[index - 1] || str[index - 1] === '\n') && (str[i] === '\n' || i === str.length - 1) && data.text.length >= 3) {
            data.index = i;
            data.judeg = true;
            data.text = '<div class="markdown-paragraph"><span class="markdown-' + label + vlaue + '">' + data.text.replace(new RegExp('^(#{' + vlaue + '})(.*)'), '<span class="markdown-' + label + vlaue + '-symbol">$1</span><span class="markdown-p">$2</span>').replace(/^\n|\n$/g, "") + '</span></div>';
            return data;
          }
        }
        break;
    }
    return data;
  }

  /**
   * 对整体进行封装
   * @param {String} markdownText 字符串
   */
  function matchingSymbolFindPostposition(markdownText) {
    markdownText = markdownText.replace(/^\n|\n$/g, "");
    let segmentationArr = markdownText.split('\n');
    let segmentationText = '<div class="markdown-paragraph">';
    for (let i = 0; i < segmentationArr.length; i++) {
      if (segmentationArr[i] !== null && /^=+$/.test(segmentationArr[i + 1])) {
        segmentationText += '<span class="markdown-h1"><span class="markdown-p">' + segmentationArr[i] + '</span>\n<span class="markdown-h1-symbol">' + segmentationArr[i + 1] + '</span></span>';
        i++;
      } else if (segmentationArr[i] !== null && /^-+$/.test(segmentationArr[i + 1])) {
        segmentationText += '<span class="markdown-h2"><span class="markdown-p">' + segmentationArr[i] + '</span>\n<span class="markdown-h1-symbol">' + segmentationArr[i + 1] + '</span></span>';
        i++;
      } else {
        segmentationText += '<span class="markdown-p">' + segmentationArr[i] + '</span>';
      }
      if (i !== segmentationArr.length - 1) {
        segmentationText += '\n';
      }
    }
    // console.log(JSON.stringify(segmentationText));
    return segmentationText += '</div>';
  }

  /**
   * 获取光标位置
   */
  function saveCaretPosition(element) {
    let selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      let range = selection.getRangeAt(0);
      let startContainer = range.startContainer;
      let startOffset = range.startOffset;
      let offset = 0;
      let stack = [{ node: element, index: 0 }];
      while (stack.length > 0) {
        let { node, index } = stack.pop();
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (node === startContainer) {
            caretOffset = ++offset;
            posType = true;
          } else if (node.nodeName === "BR") {
            offset++;
          } else {
            for (let i = node.childNodes.length - 1; i >= 0; i--) {
              stack.push({ node: node.childNodes[i], index: 0 });
            }
          }
        } else if (node.nodeType === Node.TEXT_NODE) {
          if (node === startContainer) {
            caretOffset = offset + startOffset;
            posType = false;
          } else {
            offset += node.length;
          }
        }
      }
    }
  }

  /**
   * 设置光标
   */
  function restoreCaretPosition(element, caretOffset) {
    // console.log('上个偏移量-------：' + caretOffset);
    let selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      let range = selection.getRangeAt(0);
      let offset = 0;
      let brOffset = 0;
      let nodeV;
      let offsetV;
      let stack = [{ node: element, index: 0 }];
      while (stack.length > 0) {
        let { node, index } = stack.pop();
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.nodeName === "BR") { // 空行验证
            if (offset + brOffset + 1 === caretOffset) { // 判断上个行的类型处理换行位置
              offsetV = 0;
              let paragraphElement = node.closest('.markdown-paragraph');

              function posTypeJudeg(posType) { nodeV = posType ? node : paragraphElement.nextElementSibling.firstChild.firstChild; }
              if (keydown === 'Enter') {
                posTypeJudeg(posType);
              } else if (keydown === 'Backspace') {
                // 处理多行与单行
                if (paragraphElement.innerText === '\n') {
                  posTypeJudeg(posType);
                } else {
                  nodeV = getAllTextNodes(element, getAllTextNodes(element).textNode.substr(0, caretOffset).match(/\n/g).length).nodeLine
                }
              } else {
                // 正常文本输入
                nodeV = node.parentNode.nextSibling;
                if (!nodeV) {
                  nodeV = node;
                } else {
                  offsetV = caretOffset - offset - brOffset;
                }
              }
              break;
            } else {
              brOffset++;
            }
          } else {
            // 将节点进行有序压栈
            for (let i = node.childNodes.length - 1; i >= 0; i--) {
              stack.push({ node: node.childNodes[i], index: 0 });
            }
          }
        } else if (node.nodeType === Node.TEXT_NODE) {
          if (offset + node.length + brOffset >= caretOffset) {
            offsetV = 0;
            let paragraphElement = node.parentNode.closest('.markdown-paragraph');

            function contextJudeg(contextOffset, blankLineOffset) {
              if (contextOffset) {
                nodeV = paragraphElement.nextElementSibling.querySelector('br');
              } else {
                if (paragraphElement.previousElementSibling) {
                  nodeV = paragraphElement.previousElementSibling.querySelector('br');
                } else {
                  if (blankLineOffset) {
                    nodeV = blankLineChange(element, paragraphElement);
                  } else if (nodeV) {
                    nodeV = paragraphElement.previousElementSibling.querySelector('br');
                  } else {
                    nodeV = node;
                  }
                }
              }
            }
            if (keydown === 'Enter') {
              // 判断是否于文本中换行
              if (paragraphElement.innerText.includes('\n')) {
                nodeV = node.nextSibling.nextSibling; // 将光标置于段落分割处开头
              } else {
                nodeV = node; // 将光标置于本段落开头
              }
              // 判断删除后是否处于空行
            } else if (keydown === 'Backspace' && (posType || mergeOffset)) {
              if (posType) {
                contextJudeg(contextOffset, blankLineOffset);
              }
              if (mergeOffset) {
                nodeV = node.nextSibling.nextSibling;
              }
            } else {
              if (cutOffset) {
                contextJudeg(contextOffset, blankLineOffset);
              } else {
                nodeV = node;
                offsetV = caretOffset - offset - brOffset;
                if (pasteOffset) offsetV++;
              }
            }
            break;
          } else {
            offset += node.length;
          }
        }
      }
      range.setStart(nodeV, offsetV);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  /**
   * 递归获取全文
   * @param {Object} element 需要遍历的元素
   * @param {Object} line 需要获取文本节点的行数
   */
  function getAllTextNodes(element, line) {
    let i = 0;
    let nodeLine;
    let textNode = '';
    let stack = [{ node: element, index: 0 }];
    while (stack.length > 0) {
      let { node, index } = stack.pop();
      if (node.nodeType === Node.TEXT_NODE) {
        textNode += node.textContent;
        if (line && line === i) {
          nodeLine = node;
          break;
        } else {
          i++;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === "BR") {
          textNode += '\n';
        } else {
          for (let i = node.childNodes.length - 1; i >= 0; i--) {
            stack.push({ node: node.childNodes[i], index: 0 });
          }
        }
      }
    }
    return { textNode, nodeLine };
  }

  /**
   * 创建空行
   */
  function blankLineChange(element, referElement) {
    let blankLine = document.createElement('div');
    blankLine.classList.add('markdown-paragraph');
    let span = document.createElement('span');
    span.classList.add('markdown-br');
    let br = document.createElement('br');
    span.appendChild(br);
    blankLine.appendChild(span);
    element.insertBefore(blankLine, referElement);
    return br;
  }
</script>

<style scoped>
  @import "@/assets/css/share/universal.css";

  .markdown-editor-base {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(220, 221, 225, 1.0);
  }

  .markdown-editor-tool-menu-box {
    width: 100%;
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .markdown-editor-compile-box {
    position: relative;
    margin-top: 1px;
    width: 100%;
    height: calc(100% - 67px);
    display: flex;
    overflow: hidden;
  }

  .markdown-editor-compile-write {
    position: relative;
    outline: none;
    width: 100%;
    min-height: 20px;
    height: calc(100% - auto);
    font-size: 15px;
    padding: 20px 20px 40vh 20px;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .markdown-editor-compile-write::-webkit-scrollbar {
    width: 8px;
  }

  .markdown-editor-compile-write::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .markdown-editor-compile-write::-webkit-scrollbar-thumb {
    background-color: rgba(127, 140, 141, 0.5);
    height: 5px;
    cursor: pointer;
  }

  .markdown-editor-complie-preview {
    outline: none;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    overflow-wrap: break-word;
  }

  .markdown-editor-complie-preview::-webkit-scrollbar {
    width: 8px;
  }

  .markdown-editor-complie-preview::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .markdown-editor-complie-preview::-webkit-scrollbar-thumb {
    background-color: rgba(127, 140, 141, 0.7);
    height: 5px;
    cursor: pointer;
  }

  .markdown-body {
    padding: 0px 20px;
    width: calc(100% - 40px);
    height: auto;
    font-size: 18px;
  }

  .markdown-editor-record-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 25px;
    height: 25px;
    background-color: white;
  }

  .markdown-editor-tool-left-box {
    width: 500px;
    margin-left: 20px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .markdown-editor-outer-box {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
  }

  .markdown-editor-within-box {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    /* border: 1px solid red; */
  }

  .markdown-editor-within-box:hover {
    background-color: rgba(45, 52, 54, 0.1);
  }

  .markdown-editor-within-box button {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background: none;
  }

  .markdown-editor-within-box button:focus {
    outline: none;
  }

  .markdown-editor-within-box button:hover {
    background: none;
  }

  .markdown-editor-within-box button:active {
    border: none;
  }

  .markdown-editor-icon {
    font-size: 20px;
    color: black;
  }

  .markdown-editor-elment-menu-box {
    width: 30px;
    height: 30px;
  }

  .markdown-editor-elment-menu-box button {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .markdown-editor-elment-menu-box button:hover {
    background: none;
  }

  .markdown-editor-elment-menu-box button:focus {
    border: none;
  }
</style>
