<!-- 密码修改框 -->
<template>
  <div class="input_pwd-shade">
    <div class="input_pwd-box">
      <div class="input_pwd-close-box">
        <span class="input_pwd-hint">密码修改</span>
        <div class="input_pwd-close" @click="cancelSubmit">
          <span class="iconfont">&#xe65d;</span>
        </div>
      </div>
      <form>
        <div class="input_pwd-password-box">
          <div class="input_pwd-password">
            <div class="input_pwd-password-eye">
              <span class="iconfont" v-html="pwdSwitchPric" @click="pwdSwitchShow"></span>
              <input ref="inputRef" class="input_pwd-input" v-model="inputDataPwd" :type="inputType" placeholder="新密码" autocomplete @keydown.enter="confirmSubmit" />
            </div>
            <div></div><input class="input_pwd-input" v-model="inputDataAffirmPwd" :type="inputType" placeholder="确认密码" autocomplete @keydown.enter="confirmSubmit" />
          </div>
        </div>
      </form>
      <div class="input_pwd-submit-box">
        <button class="input_pwd-cancel" @click="cancelSubmit">取消</button>
        <button class="input_pwd-affirm" @click="confirmSubmit">确定</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    inject,
    onMounted,
    defineProps,
    defineEmits
  } from 'vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    inputRef.value.focus();
  });

  // 常量
  const emits = defineEmits();
  const commonUtils = inject('$commonUtils');

  // 变量
  let inputRef = ref(null);
  let pwdSwitchPric = ref('&#xe6fb;');
  let inputType = ref('password');
  let inputDataPwd = "";
  let inputDataAffirmPwd = "";

  /**
   * 输入提交
   */
  function confirmSubmit() {
    if (!/^\s*$/.test(inputDataPwd) && !/^\s*$/.test(inputDataAffirmPwd)) {
      if (inputDataPwd === inputDataAffirmPwd) {
        emits('inputPwdEmits', {
          judeg: true,
          data: inputDataPwd
        });
      } else {
        commonUtils.elmentMessage('需输入相同密码', 'warning', 30);
      }
    } else {
      commonUtils.elmentMessage('格式不为空', 'warning', 30);
    }
  }

  /**
   * 输入取消
   */
  function cancelSubmit() {
    emits('inputPwdEmits', {
      judeg: false
    });
  }

  /**
   * 密码显示控制
   */
  function pwdSwitchShow() {
    if (pwdSwitchPric.value === '&#xe6fb;') {
      inputType.value = 'text';
      pwdSwitchPric.value = '&#xe6f9;';
    } else {
      inputType.value = 'password';
      pwdSwitchPric.value = '&#xe6fb;';
    }
  }
</script>

<style scoped>
  .input_pwd-shade {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 39, 46, 0.5);
    z-index: 2000;
  }

  .input_pwd-box {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 300px;
    height: 180px;
    background-color: white;
    box-shadow: 0 0 15px rgba(53, 59, 72, 0.5);
  }

  .input_pwd-close-box {
    height: 20%;
    display: flex;
    align-items: center;
    background-color: rgba(53, 59, 72, 1.0);
  }

  .input_pwd-hint {
    max-width: 70%;
    user-select: none;
    color: white;
    font-size: 15px;
    margin-left: 20px;
    color: rgba(220, 221, 225, 0.8);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input_pwd-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .input_pwd-close:active {
    background-color: rgba(220, 221, 225, 0.2);
  }

  .input_pwd-close span {
    user-select: none;
    font-size: 17px;
    color: rgba(241, 242, 246, 0.9);
  }

  .input_pwd-password-box {
    user-select: none;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input_pwd-password {
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .input_pwd-password-eye{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input_pwd-password-eye span {
    position: absolute;
    margin: 0;
    right: 20px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: rgba(75, 75, 75, 1.0);
    background-color: rgba(241, 242, 246, 1.0);
  }

  .input_pwd-input {
    margin-top: 3px;
    margin-bottom: 3px;
    outline: none;
    width: 80%;
    height: 15px;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid gray;
  }

  .input_pwd-input:focus {
    border: 1px solid black;
  }

  .input_pwd-submit-box {
    user-select: none;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .input_pwd-submit-box button {
    width: 35%;
    height: 70%;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .input_pwd-affirm {
    border: none;
    background-color: rgba(0, 168, 255, 0.8);
    color: white;
  }

  .input_pwd-affirm:active {
    background-color: rgba(0, 168, 255, 0.5);
  }

  .input_pwd-cancel {
    border: none;
    background-color: rgba(225, 177, 44, 0.8);
    color: white;
  }

  .input_pwd-cancel:active {
    background-color: rgba(225, 177, 44, 0.5);
  }
</style>
