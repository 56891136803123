<!-- 文章内容子组件页面 -->
<template>
  <div class="content_mangement-base" v-loading="loading" element-loading-background="rgba(255, 255, 255,0.3)">
    <!-- 选择区 -->
    <div class="content_mangement-top-box">
      <span>选择区，待开发!</span>
    </div>
    <!-- 展示区 -->
    <div class="content_mangement-exhibition-box">

      <div class="content_mangement-article-list" v-for="(item, index) in articleList">
        <div class="content_mangement-article-list-left-box">
          <img :src="imagesPathApi + item.cover" draggable="false" alt="" />
        </div>
        <div class="content_mangement-article-list-right-box">
          <div class="content_mangement-article-list-top">
            <div class="content_mangement-article-list-top-left">
              <span class="content_mangement-title">{{ item.title }}</span>
            </div>
          </div>
          <div class="content_mangement-article-list-centre"></div>
          <div class="content_mangement-article-list-bottom">
            <span class="content_mangement-timestamp">{{ item.timestamp }}</span>
            <Dropdown class="content_mangement-dropdown" :control="dropdownControl" :data="{normalcyColor: 'rgba(75, 75, 75,0.7)', dynamicColor: 'rgba(75, 75, 75,1.0)', fontSize: '15px'}">
              <template v-slot:menus>
                <button class="content_mangement-article-preview" @click.stop="commonUtils.newSkip(`/article/browsing?aid=${item.aid}`)">预览</button>
                <button>编辑</button>
                <button @click.stop="deleteHint(item.aid)">删除</button>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>

      <div v-show="pagingSum" class="content_mangement-paging-box">
        <Paging ref="pagingRef" :sum="pagingSum" @pagingEmits="pagingMonitor"></Paging>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    defineEmits,
    defineExpose
  } from 'vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    dataInitialize();
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {

  });

  //常量
  const emits = defineEmits();
  const axiosApi = inject('$axiosApi');
  const commonUtils = inject('$commonUtils');
  const imagesPathApi = inject('$imagesPathApi');

  // 变量
  let device = commonUtils.equipmentJudeg();
  let dropdownControl = ref(device.value === 'PC' ? 'hover' : 'click');
  let pagingRef = ref(null);
  let pagingSum = ref(0);
  let articleList = ref([]);
  let loading = ref(false);

  defineExpose({ deleteArtic });

  /**
   * 页面初始化
   */
  function dataInitialize() {
    gainArticleAxios(pagingRef.value.pagingLast, pagingRef.value.strip);
  }

  /**
   * 分页获取所有文章
   * @param {Object} limitOrigin
   * @param {Object} limitStrip
   */
  function gainArticleAxios(limitOrigin, limitStrip) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      loading.value = true;
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      formData.append('limitOrigin', limitOrigin * limitStrip);
      formData.append('limitStrip', limitStrip);
      axiosApi.routine('/article/get_article_limit', 'POST', true, formData, true)
        .then(result => {
          pagingSum.value = result.sum;
          articleList.value = JSON.parse(result.data);
          loading.value = false;
        }).catch(error => { loading.value = false; });
    } else {
      commonUtils.elmentMessage('请先登录', 'info', 60);
    }
  }

  /**
   * 监听分页传值
   */
  function pagingMonitor(params) {
    gainArticleAxios(params.limitOrigin, params.limitStrip);
  }

  /**
   * 删除文章请求
   * @param {String} aid 文章id
   */
  function deleteArtic(aid) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      const formData = new FormData();
      formData.append('aid', aid);
      axiosApi.routine('/article/delete_artic', 'POST', true, formData, true)
        .then(result => {
          if (result === 1) {
            pagingRef.value.paginationJudeg = true;
            gainArticleAxios(pagingRef.value.pagingLast - 1, pagingRef.value.strip);
            commonUtils.elmentMessage('删除成功', 'info', 60);
          } else {
            commonUtils.elmentMessage('删除失败', 'error', 60);
          }
        }).catch(error => { commonUtils.elmentMessage('删除异常' + error, 'error', 60); });
    } else {
      commonUtils.elmentMessage('请先登录', 'info', 60);
    }
  }

  /**
   * 删除文章
   */
  function deleteHint(aid) {
    emits('contentManagementEmits', {
      judeg: true,
      aid: aid,
      message: "是否删除文章？删除后将不可恢复！"
    });
  }
</script>

<style scoped>
  .content_mangement-base {
    width: 100%;
    height: 100%;
  }

  .content_mangement-top-box {
    height: 150px;
    background-color: black;
  }

  .content_mangement-exhibition-box {
    min-height: calc(100% - 150px);
  }

  .content_mangement-article-list {
    height: 120px;
    border-bottom: 1px solid rgba(164, 176, 190, 0.3);
    transition: all 0.3s ease-in-out;
    display: flex;
  }

  .content_mangement-article-list-top {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
  }

  .content_mangement-article-list-top-left {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .content_mangement-title {
    position: absolute;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  .content_mangement-article-list-left-box {
    width: 200px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content_mangement-article-list-left-box img {
    width: 180px;
    height: 100px;
    border-radius: 3px;
  }

  .content_mangement-article-list-right-box {
    width: calc(100% - 200px);
  }

  .content_mangement-article-list-centre {
    width: 100%;
    height: 30px;
  }

  .content_mangement-article-list-bottom {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .content_mangement-dropdown {
    margin-right: 30px;
  }

  .content_mangement-paging-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
  }

  .content_mangement-timestamp {
    margin-left: 30px;
    margin-right: auto;
    font-size: 10px;
    color: gray;
  }
</style>
